<template>
  <div>
    <v-container fluid fill-height>
      <!-- Header -->
      <v-row>
        <v-col class="text-center mb-3">
          <h2 style="padding: 20px">{{ $t('PHQ9.title') }}</h2>
        </v-col>
      </v-row>
      <!-- Introduction -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 text-center">
          <p class="font-weight-light">{{ $t('PHQ9.introduction') }}</p>
          <p class="font-weight-light"><Phq9Popup /></p>
        </v-col>
      </v-row>
      <!-- Start PHQ9 -->
      <StartPHQ9 />
      <!-- Header past results -->
      <v-row style="padding-top: 50px">
        <v-col class="col-md-4 offset-md-4 text-center">
          <h2>{{ $t('PHQ9.pastResults') }}</h2>
        </v-col>
      </v-row>
      <!-- List of done PHQs -->
      <DonePHQ9s v-bind:resultPage="false" />
    </v-container>
  </div>
</template>

<script>
import Phq9Popup from '../components/Phq9Popup.vue';
import StartPHQ9 from '../components/StartPHQ9.vue';
import DonePHQ9s from '../components/DonePHQ9s.vue';

export default {
  name: 'Selfassessment',
  components: { Phq9Popup, StartPHQ9, DonePHQ9s },
};
</script>
<style lang="scss">
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.box {
  height: 15px;
  width: 15px;
  color: #41257e;
  background-color: #41257e;
  border-radius: 50%;
  margin-bottom: 20px;
}
</style>
