import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import Amplify from 'aws-amplify';
import ToggleButton from 'vue-js-toggle-button';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import App from './App.vue';
import './filters/cron2date';
import './filters/license';
import './plugins/axios';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './utils/validateSession';

Amplify.configure({
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
});

if (process.env.VUE_APP_SENTRY_ENABLED) {
  Sentry.init({
    Vue,
    dsn: 'https://d763c4fd875ce941f7298e36934ce92a@o4504373436153856.ingest.sentry.io/4506161575755776',
    environment: process.env.VUE_APP_UNITY_ANALYTICS_ENV,
    integrations: [new Sentry.Replay()],
    // Session Replay
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.use(VueFilterDateFormat);
Vue.use(ToggleButton);

Vue.config.productionTip = false;

new Vue({
  // @ts-ignore
  vuetify,
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
