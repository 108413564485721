<template>
  <div>
    <v-main>
      <v-container fluid fill-height>
        <v-row>
          <v-col class="col-md-4 offset-md-4">
            <v-card text color="primaryDark" flat class="text-center pa-5 mt-12">
              <h2 class="py-2">{{ $t('addLicense.license') }}</h2>
              <p>{{ $t('addLicense.description') }}</p>
              <p>{{ $t('addLicense.label') }}</p>
              <div :class="isLicenseValid ? 'mx-5' : 'mx-5 mgl-expired'">
                <v-text-field
                  :placeholder="$t('addLicense.header')"
                  outlined
                  rounded
                  class="mgl-append-green"
                  color="secondary"
                  type="text"
                  ref="license"
                  :disabled="isLicenseValid"
                  :append-icon="isLicenseValid ? 'mdi-check' : ''"
                  :rules="[rules.required, rules.length11, rules.format]"
                  v-model="tempLicense"
                  @input="tempLicense = tempLicense.replace(/\s+/g, '')"
                  @keydown.space.prevent=""
                  @paste="handlePaste"
                ></v-text-field>
                <v-btn
                  rounded
                  depressed
                  block
                  x-large
                  :disabled="!allowLicense || !isFormValid"
                  color="secondary"
                  class="mb-5"
                  @click="addLicense"
                  >{{ $t('addLicense.activate') }}</v-btn
                >
              </div>
              <p>{{ $t('addLicense.inEmail') }}</p>
              <BetaSignupLinks v-bind:show="!isLicenseValid" />
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!isLicenseValid">
          <v-col>
            <a
              color="primary"
              href="mailto:support@mindforcegamelab.com"
              class="float-right white--text text-decoration-underline"
              >{{ $t('addLicense.askFor') }}</a
            >
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import BetaSignupLinks from './components/BetaSignupLinks.vue';
import { addNewLicense } from '../utils/licenses';
import { displayError, setLoading } from '../utils/loading';
import { postEvent } from '../api/unityAnalytics';

export default {
  name: 'AddLicense',
  components: {
    BetaSignupLinks,
  },
  data() {
    return {
      tempLicense: '',
      isFormValid: false,
      rules: {
        required: (v) => !!v || this.$i18n.t('validation.licenseRequired'),
        format: (v) => /^[a-zA-Z0-9%+\-_]+$/.test(v) || this.$i18n.t('validation.licenseFormat'),
        length11: () => true, // skipping validation of length, since some existing licenses have different length
      },
    };
  },
  methods: {
    handlePaste(event) {
      const pastedText = event.clipboardData.getData('text/plain');
      const formattedText = pastedText.replace(/\s+/g, '');
      this.tempLicense = formattedText;
      event.preventDefault();
    },
    async addLicense() {
      setLoading(this.$store, true);
      try {
        const valid = await addNewLicense(this.$store, this.$router, this.$i18n, this.tempLicense);
        if (valid) {
          if (this.$store.state.api.me.type === 'Team') {
            this.$store.commit('api/setUserInfo', { type: 'Playtient' });
          }
          // Send event to Unity Analytics
          await postEvent(this.$store, 'OnboardingStepCompleted', {
            onboardingStep: 'license',
          });
          this.$router.push({ name: 'home' });
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            this.$refs.license.focus();
            displayError(this.$store, error.response.data);
          } else {
            displayError(this.$store, error.message);
          }
        } else {
          displayError(this.$store);
        }
      }
    },
  },
  computed: {
    allowLicense() {
      if (
        !this.license ||
        (this.type === 'MGL' && process.env.VUE_APP_ALLOW_MULTIPLE_MGL_LICENSES)
      ) {
        return true;
      }
      return false;
    },
    isLicenseValid() {
      if (!this.license) {
        return false;
      }
      return this.$options.filters.license(this.license, 'isValid');
    },
    license() {
      return this.$store.state.api.me.licenses[0];
    },
  },
  mounted() {
    const { licenses } = this.$store.state.api.me;
    if (licenses.length > 0) {
      this.tempLicense = licenses[0].id;
    }
    if (!this.isLicenseValid) {
      this.$refs.license.focus();
    }
  },
  watch: {
    tempLicense() {
      this.$nextTick(() => {
        this.isFormValid = this.$refs.license.validate();
      });
    },
  },
};
</script>
