/**
 * Make an api request using a store api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} moduleName
 * @param {string} functionName
 * @param {object} [data]
 * @returns {Promise<any>}
 */
async function dispatch(store, router, moduleName, functionName, data) {
  const payload = { store, router };
  if (data) {
    payload.data = data;
  }
  return store.dispatch(`${moduleName}/${functionName}`, payload);
}

/**
 * Make an api request using store user api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} functionName
 * @param {object} [data]
 * @returns {Promise<any>}
 */
export async function apiDispatch(store, router, functionName, data) {
  return dispatch(store, router, 'api', functionName, data);
}

/**
 * Make an api request using store anonymous api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} functionName
 * @param {object} [data]
 * @returns {Promise<any>}
 */
export async function anonymousApiDispatch(store, router, functionName, data) {
  return dispatch(store, router, 'anonymousApi', functionName, data);
}
