import Vue from 'vue';

const parser = require('cron-parser');

function cronttabToDate(crontab, func) {
  const crondate = parser.parseExpression(crontab);
  switch (func) {
    case 'hoursMinutes': {
      return Vue.filter('dateFormat')(crondate.next().toDate(), 'HH:mm');
    }
    case 'weekDays': {
      const indexCronDays = crontab.split(',');
      const indexDays = indexCronDays.map((x) => x.substr(x.length - 1));
      const weekDays = [
        { name: 'mon', label: 'M', index: '1' },
        { name: 'tue', label: 'T', index: '2' },
        { name: 'wed', label: 'W', index: '3' },
        { name: 'thu', label: 'T', index: '4' },
        { name: 'fri', label: 'F', index: '5' },
        { name: 'sat', label: 'S', index: '6' },
        { name: 'sun', label: 'S', index: '0' },
      ];

      const nameReminderDays = [];

      // Every weekday
      if (indexDays[0] === '*') {
        return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      }
      indexDays.forEach((dayNumber) => {
        weekDays.forEach((weekDay) => {
          if (weekDay.index === dayNumber) {
            nameReminderDays.push(weekDay.name);
          }
        });
      });
      return nameReminderDays;
    }
    default:
      return crondate.next().toString();
  }
}

Vue.filter('cron2date', cronttabToDate);
