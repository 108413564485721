<template>
  <div>
    <v-dialog v-model="dialogOpen" max-width="500px" content-class="elevation-0">
      <template v-slot:activator="{ on }">
        <button
          style="
            border-radius: 50%;
            width: 20px;
            height: 20px;
            padding-top: 2px;
            padding-right: 1px;
            margin: 10px;
            background: transparent;
            border: 1px solid grey;
            color: grey;
            text-align: center;
            font: 12px Arial, sans-serif;
            text-decoration-color: #c6c4c8;
            position: absolute;
            right: 0;
          "
          v-on="on"
        >
          ?
        </button>
      </template>
      <div style="padding: 50px 10px 20px 10px; background-color: transparent; overflow: hidden">
        <v-card
          style="
            border-radius: 20px;
            text-align: center;
            vertical-align: top;
            display: inline-block;
            width: 100%;
            background-color: transparent;
          "
          color="primary"
        >
          <button type="button" class="close" @click="dialogOpen = false">X</button>
          <v-card-title style="display: flex; justify-content: center">
            <h3 style="padding-bottom: 20px; text-align: center; word-break: break-word">
              {{ $t('PHQ9.severityScore') }}
            </h3>
          </v-card-title>
          <div style="padding: 5px 0px 5px 0px; margin-left: 10px; margin-right: 10px">
            <div style="display: flex; flex-direction: row; justify-content: flex-start">
              <p style="margin: 0px; display: flex; flex: 1 1 0px">
                <b>{{ $t('PHQ9.phq9score') }}</b>
              </p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                <b> {{ $t('PHQ9.depressionSymptoms') }} </b>
              </p>
            </div>
          </div>
          <div
            style="
              padding: 5px 0px 5px 20px;
              background-color: #5e3aab;
              margin-left: 10px;
              margin-right: 10px;
            "
          >
            <div
              style="
                padding: 5px 0px 5px 0px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <p style="display: flex; flex: 1 1 0px; margin: 0px"><b>0 - 4</b></p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                {{ $t('PHQ9.none') }}
              </p>
            </div>
          </div>
          <div style="padding: 5px 0px 5px 20px; margin-left: 10px; margin-right: 10px">
            <div
              style="
                padding: 5px 0px 5px 0px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <p style="display: flex; flex: 1 1 0px; margin: 0px"><b>5 - 9</b></p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                {{ $t('PHQ9.mild') }}
              </p>
            </div>
          </div>
          <div
            style="
              padding: 5px 0px 5px 20px;
              background-color: #5e3aab;
              margin-left: 10px;
              margin-right: 10px;
            "
          >
            <div
              style="
                padding: 5px 0px 5px 0px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <p style="display: flex; flex: 1 1 0px; margin: 0px"><b>10 - 14</b></p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                {{ $t('PHQ9.moderate') }}
              </p>
            </div>
          </div>
          <div style="padding: 5px 0px 5px 20px; margin-left: 10px; margin-right: 10px">
            <div
              style="
                padding: 5px 0px 5px 0px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <p style="display: flex; flex: 1 1 0px; margin: 0px"><b>15 - 19</b></p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                {{ $t('PHQ9.moderateSevere') }}
              </p>
            </div>
          </div>
          <div
            style="
              padding: 5px 0px 5px 20px;
              background-color: #5e3aab;
              margin-left: 10px;
              margin-right: 10px;
            "
          >
            <div
              style="
                padding: 5px 0px 5px 0px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <p style="display: flex; flex: 1 1 0px; margin: 0px"><b>20 - 27</b></p>
              <p style="display: flex; flex: 2 1 0px; font-weight: 400; margin: 0px">
                {{ $t('PHQ9.severe') }}
              </p>
            </div>
          </div>
          <p
            style="
              font-weight: 400;
              max-width: 500px;
              word-break: break-word;
              margin-top: 20px;
              margin-bottom: 30px;
              padding: 20px;
              text-align: left;
            "
          >
            {{ $t('PHQ9.ifQuestion') }}
          </p>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ScorePopup',
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>

<style scoped>
btn:hover {
  cursor: pointer;
}
.close {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #a55dff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 4px solid #301d59;
  z-index: 6000;
  margin: -10px;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 450px) {
  div {
    font-size: 12px;
    line-height: 150%;
  }
  p {
    font-size: 12px;
    line-height: 150%;
  }
}
</style>
