<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>

    <SimpleLocalePicker class="mgl-simple-locale" />
    <v-main key="1" v-show="show">
      <v-form>
        <v-container fluid fill-height>
          <v-row class="mt-1">
            <v-col class="col-md-6 offset-md-3 mt-6">
              <v-card color="primaryDark">
                <h2 class="pa-5 d-flex align-center justify-center">
                  {{ $t('resetPassword.reset') }}
                </h2>
                <p class="pa-5 d-flex align-center justify-center text-center">
                  {{ $t('resetPassword.information') }}
                </p>
                <v-form
                  ref="emailform"
                  v-model="isEmailValid"
                  color="primary"
                  flat
                  rounded
                  class="pa-5"
                >
                  <v-text-field
                    name="email"
                    :placeholder="$t('login.email')"
                    ref="emailinfo"
                    outlined
                    rounded
                    color="accent"
                    prepend-inner-icon="mdi-email-outline"
                    class="mgl-prepend-absolute mgl-noborder"
                    type="text"
                    :rules="[rules.email]"
                    validate-on-blur
                    v-model="email"
                    background-color="primary"
                    @input="updateEmail"
                    @keydown.space.prevent=""
                    @paste="handlePaste"
                  ></v-text-field>

                  <v-btn
                    color="secondary"
                    @click="emailSent"
                    rounded
                    depressed
                    :disabled="!isEmailValid"
                    block
                    x-large
                    class="px-10 dark"
                    >{{ $t('resetPassword.sendEmail') }}</v-btn
                  >
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-footer key="2" v-show="show" class="text-center" color="background">
        <v-container>
          <v-row class="mt-15">
            <v-col>
              {{ $t('resetPassword.needHelp') }}
              <a class="pl-2" href="mailto:support@mindforcegamelab.com">
                <u>{{ $t('resetPassword.contact') }}</u>
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import axios from 'axios';
import Logo from '../components/Logo.vue';
import { displayMessage } from '../utils/loading';
import SimpleLocalePicker from '../components/SimpleLocalePicker.vue';

export default {
  name: 'ResetPassword',
  components: {
    SimpleLocalePicker,
    Logo,
  },
  data() {
    return {
      email: '',
      password: '',
      isEmailValid: false,
      show: false,
      rules: {
        notEmpty: (v) => v.length > 0 || this.$i18n.t('validation.fieldNotEmpty'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('resetPassword.wrongEmail'),
      },
    };
  },
  methods: {
    handlePaste(event) {
      const pastedText = event.clipboardData.getData('text/plain');
      const formattedText = pastedText.replace(/\s+/g, '').toLowerCase();
      this.email = formattedText;
      event.preventDefault();
    },
    // sending password restoration email by post request
    async emailSent() {
      const emailValue = this.$refs.emailinfo.value;

      const emailAdress = await axios(`${process.env.VUE_APP_API}/recover/start`, {
        method: 'POST',
        headers: {
          Accept: 'text/plain; charset=utf-8',
          'Content-Type': 'application/problem+json; charset=utf-8',
        },
        data: JSON.stringify({ email: emailValue }),
      });
      await emailAdress.data;

      displayMessage(
        this.$store,
        this.$i18n.t('resetPassword.sentPassword', { emailValue }),
        'success',
      );
      this.$refs.emailform.reset();
    },
    updateEmail() {
      if (this.email != null) {
        this.email = this.email.replace(/\s+/g, '');
        this.email = this.email.toLowerCase();
      } else {
        this.email = '';
      }
    },
  },
  mounted() {
    this.show = true;
  },
};
</script>
<style scoped>
div.v-toolbar__content {
  display: none;
}
</style>
