var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"primary pt-8 pb-4 px-5",staticStyle:{"max-width":"500px","margin":"auto"},attrs:{"elevation":"0","rounded":"xl"}},[_c('h2',{staticClass:"ma-0 text-center"},[_vm._v(" "+_vm._s(_vm.$t('reports.steps'))+" ")]),_c('p',{staticClass:"pa-5 text-center subtitle",domProps:{"innerHTML":_vm._s(
        _vm.$t('reports.stepsResultHtml', {
          numberOfSteps: 5000,
        })
      )}}),_c('section',{ref:"graph",staticClass:"graph"},[_c('div',{staticClass:"yaxis"},[_c('p',[_vm._v("500")]),_c('p',[_vm._v("1000")]),_c('p',[_vm._v("1500")]),_c('p',[_vm._v("2000")]),_c('p',[_vm._v("2500")]),_c('p',[_vm._v("3000")]),_c('p',[_vm._v("3500")]),_c('p',[_vm._v("4000")]),_c('p',[_vm._v("4500")]),_c('p',[_vm._v("5000")]),_c('p',[_vm._v("5500")]),_c('p',[_vm._v("6000")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"steps-goal",staticStyle:{"bottom":"365px"}},[_c('p',[_vm._v("step goal (5000)")])]),_c('div',{staticClass:"bars"},[_c('div',{staticStyle:{"height":"285px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("4000")])])],1),_c('div',{staticStyle:{"height":"365px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("5000")])])],1),_c('div',{staticStyle:{"height":"305px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("4213")])])],1),_c('div',{staticStyle:{"height":"315px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("4304")])])],1),_c('div',{staticStyle:{"height":"30px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("750")])])],1),_c('div',{staticStyle:{"height":"450px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("6000")])])],1),_c('div',{staticStyle:{"height":"290px"}},[_c('v-tooltip',{attrs:{"zIndex":"9999","color":"secondary","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"bar-content"},'div',attrs,false),on))]}}])},[_c('span',[_vm._v("3910")])])],1)]),_c('div',{staticClass:"xaxis"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.1'))+" "),_c('br'),_vm._v(" 5/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.2'))+" "),_c('br'),_vm._v(" 6/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.3'))+" "),_c('br'),_vm._v(" 7/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.4'))+" "),_c('br'),_vm._v(" 8/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.5'))+" "),_c('br'),_vm._v(" 9/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.6'))+" "),_c('br'),_vm._v(" 10/9 ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('generic.weekdays.short.7'))+" "),_c('br'),_vm._v(" 11/9 ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }