<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.mood') }}
      </h2>

      <p
        class="pa-5 text-center subtitle"
        v-html="$t('reports.moodResultHtml', { moodLevel: 'mostly balanced' })"
      />
      <!-- Graph -->
      <section class="graph">
        <div class="yaxis">
          <div>
            <p>{{ $t('reports.moods.depression_4') }}</p>
            <div class="lines noline"></div>
          </div>
          <div>
            <p>{{ $t('reports.moods.depression_3') }}</p>
            <div class="lines">
              <div></div>
            </div>
          </div>
          <div>
            <p>{{ $t('reports.moods.depression_2') }}</p>
            <div class="lines two">
              <div></div>
              <div></div>
            </div>
          </div>
          <div>
            <p>{{ $t('reports.moods.depression_1') }}</p>
            <div class="lines two">
              <div></div>
              <div></div>
            </div>
          </div>
          <div>
            <p>{{ $t('reports.moods.depression_0') }}</p>
            <div class="lines">
              <div></div>
            </div>
          </div>
        </div>
        <div class="xaxis">
          <div>
            <p>
              {{ $t('generic.weekdays.short.1') }} <br />
              5/9
            </p>
            <div
              class="circle"
              style="background: #79c07d; transform: translate(-50%, -395px)"
            ></div>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.2') }} <br />
              6/9
            </p>
            <div
              class="circle"
              style="background: #71bdab; transform: translate(-50%, -275px)"
            ></div>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.3') }} <br />
              7/9
            </p>
            <div
              class="circle"
              style="background: #71bdab; transform: translate(-50%, -235px)"
            ></div>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.4') }} <br />
              8/9
            </p>
            <div
              class="circle"
              style="background: #8f91f0; transform: translate(-50%, -155px)"
            ></div>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.5') }} <br />
              9/9
            </p>
            <div
              class="circle"
              style="background: #8f91f0; transform: translate(-50%, -115px)"
            ></div>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.6') }} <br />
              10/9
            </p>
          </div>
          <div>
            <p>
              {{ $t('generic.weekdays.short.7') }} <br />
              11/9
            </p>
            <div
              class="circle"
              style="background: #71bdab; transform: translate(-50%, -235px)"
            ></div>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ReportMood',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
$space: 39px;
$gapStart: 20px;

.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.yaxis {
  display: flex;
  flex-direction: column;
  margin-bottom: $gapStart;
  > div {
    display: flex;
    width: 100%;
    padding-left: 8px;
    &:first-child {
      padding: 0 8px;
      padding-right: 0;
    }
    &:nth-child(odd) {
      background: #56369a;
    }
    p {
      display: flex;
      align-items: center;
      flex: 0.18;
      margin-bottom: 0;
    }
    .lines {
      padding: $space 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      > div {
        width: 100%;
        height: 1.5px;
        background-color: #875fdb;
      }
    }
    .noline {
      padding: 0;
      height: 39.75px;
    }
    .lines.two {
      > div:first-child {
        margin-bottom: $space;
      }
    }
  }
}

.xaxis {
  display: flex;
  text-align: center;
  padding-left: 80px;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  p {
    margin-bottom: 0;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -$gapStart;
    left: 50%;
  }
}
</style>
