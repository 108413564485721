/**
 * Converts base 64 to array buffer
 * @param {string} base64 base 64 encoded string
 * @returns {ArrayBufferLike}
 */
export function base64ToArraybuffer(base64) {
  return Buffer.from(base64, 'base64');
}

/**
 * Converts array buffer to base 64 string
 * @param {ArrayBufferLike} buffer
 * @returns {string}
 */
export function arraybufferTobase64(buffer) {
  return Buffer.from(buffer).toString('base64');
}

/**
 * Converts an array buffer to hex string
 * @param {ArrayBuffer} buffer array buffer
 * @param {boolean} [lowercase] if true lowercase is used for result
 * @returns {string}
 */
export function arrayBufferToHexStr(buffer, lowercase) {
  let hexStr = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    const byte = bytes[i];
    /* eslint-disable no-bitwise */
    hexStr += String.fromCharCode(
      charCodeToHexChar(byte >> 4, lowercase),
      charCodeToHexChar(byte & 0xf, lowercase),
    );
    /* eslint-enable no-bitwise */
  }
  return hexStr;
}

/**
 * Converts char code to hex char code
 * @param {number} code
 * @param {boolean|undefined} lowercase if true lowercase is used for result
 * @returns {number}
 */
function charCodeToHexChar(code, lowercase) {
  // 0 - 9
  if (code < 10) {
    return code + 48;
  }
  if (lowercase) {
    // a - f
    return code + 87;
  }
  // A - F
  return code + 55;
}

/**
 * Converts a string to base64 encoding
 * @param {string} string string to convert
 * @returns {string}
 */
export function stringToBase64(string) {
  const enc = new TextEncoder();
  const buffer = enc.encode(string);
  return arraybufferTobase64(buffer);
}
