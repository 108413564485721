import { setLoading, displayMessage, displayError } from './loading';
import { apiDispatch, anonymousApiDispatch } from './dispatch';
import { addDaysToDate, dateWithoutTime } from './datetime';

/**
 *@typedef {{id: string, groupId:null|string, updatedAt: string, validDays: number}} License
 */

const licenseGroups = {
  livsMedicin: 'livs1',
  vcls: 'vcls',
  qa: 'qa',
  preTrial: 'pretrial',
  uxTest: 'uxtest',
  betaIreland: 'betaireland',
  betaUK: 'betauk',
  betaGermany: 'betagermany',
  betaFrance: 'betafrance',
  betaUSA: 'betaus',
};

const mglGroupId = 'hbdj8rp8otbucpv7';

/**
 * Gets license group for license, or undefined if there is no group
 * @param {string} licenseId
 * @param {License[]} [licenses]
 * @returns {string|undefined}
 */
function getLicenseGroup(licenseId, licenses) {
  if (!licenses) {
    return undefined;
  }
  for (const license of licenses) {
    if (license.id === licenseId) {
      return license.groupId;
    }
  }
  return undefined;
}

/**
 * Gets array of license groups for licenses given or undefined
 * @param {License[]} [licenses]
 * @returns {string[]|undefined}
 */
export function getLicenseGroups(licenses) {
  const groupIdSet = getValidLicenseGroupIdSet(licenses);
  if (groupIdSet.size === 0) {
    return undefined;
  }
  const setArr = [];
  for (const groupId of groupIdSet) {
    setArr.push(groupId);
  }
  return setArr;
}

/**
 * Get expiry timestamp for license
 * @param {License} license
 * @returns {Date}
 */
export function getLicenseExpiryTimestamp(license) {
  const { updatedAt, validDays } = license;
  return dateWithoutTime(addDaysToDate(new Date(updatedAt), validDays));
}

/**
 * Returns true if license is valid
 * @param {License} license license
 * @param {Date} [dateNow] date for now, if not set current date is used
 * @return {boolean}
 */
export function isLicenseValid(license, dateNow) {
  const dateNowVal = dateNow || new Date();
  const expiryDate = getLicenseExpiryTimestamp(license);
  return expiryDate > dateNowVal;
}

/**
 * Gets set of valid license groups from licenses
 * @param {License[]} [licenses]
 * @returns {Set<string>}
 */
function getValidLicenseGroupIdSet(licenses) {
  const groupIdSet = new Set();
  if (!licenses) {
    return groupIdSet;
  }
  const dateNow = new Date();
  for (const license of licenses) {
    const { groupId } = license;
    if (groupId && isLicenseValid(license, dateNow)) {
      groupIdSet.add(groupId);
    }
  }
  return groupIdSet;
}

/**
 * Add a new license for a user
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {import("vue-i18n").default} i18n
 * @param {string} licenseCode
 * @returns {Promise<boolean>} A Promise that resolves to a boolean value indicating whether the license was valid.
 */
export async function addNewLicense(store, router, i18n, licenseCode) {
  const licenseKey = licenseCode.trim();
  try {
    setLoading(store, true);
    await apiDispatch(store, router, 'addLicense', {
      licenseKey,
    });
    // update anonymous user info here (if license has belongs to any group)
    const { licenses } = store.state.api.me;
    const licenseGroup = getLicenseGroup(licenseKey, licenses);
    if (licenseGroup) {
      const userLicenseGroups = getLicenseGroups(licenses);
      if (userLicenseGroups) {
        const groupIds = userLicenseGroups.join(',');
        await anonymousApiDispatch(store, router, 'setUserInfo', { groupIds });
      }
    }
    displayMessage(store, i18n.t('addLicense.success'), 'success');
    return true;
  } catch (error) {
    const { status } = error.toJSON();
    if (status === 400) {
      displayError(store, i18n.t('addLicense.invalidLicenseHtml'));
    } else {
      displayError(store, i18n.t('addLicense.licenseErrorHtml'));
    }
    return false;
  }
}

/**
 * Get group id hash or undefined
 * using given group id set
 * @param {Set<string>} groupIdSet group id s
 * @param {string} userType user type
 * @returns {string|undefined}
 */
function getGroupIdParam(groupIdSet, userType) {
  if (groupIdSet.size === 0) {
    return userType === 'MGL' ? mglGroupId : undefined;
  }
  if (groupIdSet.has(licenseGroups.vcls)) {
    return '7wb89o0c0st7lqgw';
  }
  if (groupIdSet.has(licenseGroups.livsMedicin)) {
    return 'v44s3rdz276ikrln';
  }
  if (groupIdSet.has(licenseGroups.betaUSA)) {
    return 'l49cjqrh7b5vgt1v';
  }
  if (groupIdSet.has(licenseGroups.betaUK) || groupIdSet.has(licenseGroups.betaIreland)) {
    return '04v0ebu667hfubed';
  }
  if (groupIdSet.has(licenseGroups.betaGermany)) {
    return 'es59kc3mb8j4ge2r';
  }
  if (groupIdSet.has(licenseGroups.betaFrance)) {
    return '240u81jq7p3j8ql2';
  }
  if (groupIdSet.has(licenseGroups.qa)) {
    return 'h0v7bta2kdlphb8i';
  }
  return userType === 'MGL' ? mglGroupId : undefined;
}

/**
 * Get download page url for user
 * Assumes user is logged in
 * @param {import("vuex").Store} store
 * @param {import("vue-i18n").default} i18n
 * @returns {string}
 */
export function getDownloadPageUrl(store, i18n) {
  const { licenses, type } = store.state.api.me;
  const language = i18n.locale;
  const baseUrl = process.env.VUE_APP_FIG_URL;
  if (!licenses) {
    return getDownloadPageUrlInLanguage(baseUrl, language);
  }
  const licenseGroupIdSet = getValidLicenseGroupIdSet(licenses);
  const groupParam = getGroupIdParam(licenseGroupIdSet, type);
  if (!groupParam) {
    return getDownloadPageUrlInLanguage(baseUrl, language);
  }
  return `${baseUrl}?group=${groupParam}&lang=${language}`;
}

/**
 * @param {string} baseUrl base url
 * @param {string|undefined} language user language
 * @returns {string} download page url
 */
function getDownloadPageUrlInLanguage(baseUrl, language) {
  if (!language) {
    return baseUrl;
  }
  return `${baseUrl}?lang=${language}`;
}
