<template>
  <div v-if="!report">
    {{ $t('reports.loading') }}
  </div>
  <div v-else>
    <v-container fluid fill-height class="pb-0">
      <v-row>
        <v-col class="text-center pb-0">
          <h2>{{ $t('reports.header') }}</h2>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="text-center col-10 offset-1 col-md-4 offset-md-4">
          {{ headerFormat(startDate) }} - {{ headerFormat(endDate) }}, {{ year(endDate) }}
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="text-center col-10 offset-1 col-md-4 offset-md-4">
          <p>
            {{
              $t(summary, {
                adherencePercent: this.adherencePercent,
                commonWellbeing: $t('reports.moods.' + report.commonWellbeing),
              })
            }}
          </p>
          <p>{{ $t(scoreText) }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center col-10 offset-1 col-md-6 offset-md-3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th class="text-left" v-for="day in report.days" :key="day">
                    {{ getDayOfWeek(day) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="doseHour in report.doseHours" :key="doseHour">
                  <td>{{ localizeTime(doseHour) }}</td>
                  <td v-for="day in report.details" :key="day.date">
                    <span v-for="dose in day.doses" :key="dose">
                      <span v-if="dose.time === doseHour">
                        <span v-if="dose.value === 1">
                          <v-icon color="green" size="20">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else-if="dose.value === 0">
                          <v-icon color="red" size="20">mdi-alert-circle-outline</v-icon>
                        </span>
                        <span v-else>
                          <v-icon color="grey" size="20">mdi-brightness-1</v-icon>
                        </span>
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('reports.details.mood') }}</td>
                  <td v-for="day in report.details" :key="day.index">
                    <span v-if="day.mood !== undefined">
                      {{ $t('reports.moods.' + day.mood) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { parse, format } from 'date-fns';
import { sv } from 'date-fns/locale';
import { FIG_APPID } from '../utils/constants';
import { displayError, setLoading } from '../utils/loading';
import { anonymousApiDispatch } from '../utils/dispatch';

const parser = require('cron-parser');

export default {
  name: 'ReportDetails',
  data() {
    return {
      report: undefined,
      adherencePercent: undefined,
      summary: undefined,
      scoreText: undefined,
    };
  },
  created() {
    this.fetchReport();
  },
  computed: {
    startDate() {
      return this.report !== undefined ? this.report.days[0] : '';
    },
    endDate() {
      return this.report !== undefined ? this.report.days[this.report.days.length - 1] : '';
    },
  },
  watch: {
    report() {
      // TODO: fix ambigious report prop name here
      // @ts-ignore
      this.adherencePercent = (this.report.adherenceValue / this.nrOfScheduledDoses()) * 100;
      this.setTexts();
    },
  },
  methods: {
    setTexts() {
      if (this.adherencePercent <= 60) {
        this.summary = 'reports.details.summary.textTo60';
        this.scoreText = 'reports.details.scoreText.textTo60';
      } else if (this.adherencePercent <= 84) {
        this.summary = `reports.details.summary.textTo84`;
        this.scoreText = 'reports.details.scoreText.textTo84';
      } else if (this.adherencePercent <= 99) {
        this.summary = `reports.details.summary.textTo99`;
        this.scoreText = 'reports.details.scoreText.textTo99';
      } else if (this.adherencePercent === 100) {
        this.summary = `reports.details.summary.text100`;
        this.scoreText = 'reports.details.scoreText.text100';
      }
    },
    nrOfScheduledDoses() {
      const { doses } = this.$store.state.api;
      let count = 0;
      const options = {
        currentDate: new Date(this.startDate),
        endDate: new Date(this.endDate),
        iterator: true,
      };

      doses.forEach((dose) => {
        console.log(`cron: ${dose.schedule}`);
        const interval = parser.parseExpression(dose.schedule, options);
        let obj;

        do {
          try {
            obj = interval.next();
            console.log(`Dose scheduled at ${obj.toString()}`);
            count += 1;
          } catch (e) {
            break;
          }
          // @ts-ignore
        } while (!obj.done);
      });
      return count;
    },
    locale() {
      switch (this.$i18n.locale) {
        case 'sv':
          return { locale: sv };
        default:
          return undefined;
      }
    },
    localizeTime(usFormatTime) {
      const obj = parse(usFormatTime, 'pp', new Date());
      return format(obj, 'p', this.locale());
    },
    getDayOfWeek(timestamp) {
      const obj = new Date(timestamp);
      return format(obj, 'eeee', this.locale());
    },
    headerFormat(timestamp) {
      const obj = new Date(timestamp);
      return format(obj, 'd MMMM', this.locale());
    },
    year(timestamp) {
      const obj = new Date(timestamp);
      return format(obj, 'y', this.locale());
    },
    async fetchReport() {
      try {
        setLoading(this.$store, true);
        this.report = await anonymousApiDispatch(this.$store, this.$router, 'getWeeklyReport', {
          appId: FIG_APPID,
          startDate: this.$route.query.startDate,
        });
      } catch (error) {
        displayError(this.$store, error.message);
      }
    },
  },
};
</script>
