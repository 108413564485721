<template>
  <div>
    <v-container fluid fill-height> </v-container>
  </div>
</template>

<script>
import { displayMessage, setLoading } from '../utils/loading';
import { apiDispatch } from '../utils/dispatch';
import signOut from '../utils/auth';

function showConfirmWindow(obj, textIdHeader, textIdSub, onConfirm) {
  obj.$root
    .$confirm(obj.$i18n.t(textIdHeader), obj.$i18n.t(textIdSub), {
      disableCancel: true,
      actionButtonI18nKey: 'generic.okay',
    })
    .then(onConfirm);
}
export default {
  name: 'Invites',
  computed: {
    inviteId() {
      return this.$route.params.inviteId;
    },
    loggedIn() {
      return this.$store.state.api.me != null;
    },
  },
  methods: {
    async askForInviteAcceptance(invite) {
      setLoading(this.$store, false);
      const message =
        invite.message == null
          ? this.$i18n.t('invites.noMessage')
          : this.$i18n.t('invites.message', [invite.message]);
      const confirm = await this.$root.$confirm(
        this.$i18n.t('invites.found'),
        this.$i18n.t('invites.askForAcceptanceHtml', [invite.issuerNick, message]),
        {
          asHtml: true,
          disableCancel: false,
          actionButtonI18nKey: 'generic.yes',
        },
      );
      if (!confirm) {
        this.$router.push({ name: 'login' });
        return;
      }
      console.log('Setting invite!');
      await apiDispatch(this.$store, this.$router, 'setInvitedByDetails', invite);
      setLoading(this.$store, false);
      let targetPath = 'signup';
      let messageId = 'signupRequired';
      if (invite.accountExists) {
        targetPath = 'login';
        messageId = 'loginRequired';
      }
      const query = { inviteId: invite.id };
      const { lang } = this.$route.query;
      if (lang) {
        query.lang = lang;
      }
      this.$router.push({ name: targetPath, query });
      displayMessage(this.$store, this.$i18n.t(`invites.${messageId}`), 'warning');
    },
  },
  async mounted() {
    // Log user out, since this page does not work when logged in.
    await signOut(this.$store, this.$router);

    setLoading(this.$store, true, 'invites.title', 'invites.validating');
    const payload = {
      inviteId: this.inviteId,
    };

    // Get invite
    let invite;
    try {
      invite = await apiDispatch(this.$store, this.$router, 'getInvitePublic', payload);
    } catch (e) {
      console.log(`Could not find invite with id: ${payload.inviteId}`, e);
      setLoading(this.$store, false);
      showConfirmWindow(this, 'generic.sorry', 'invites.notFound', () => {
        this.$router.push({ name: 'login' });
      });
      return;
    }

    if (Object.keys(invite).length === 0) {
      showConfirmWindow(this, 'generic.sorry', 'invites.notFound', () => {
        this.$router.push({ name: 'login' });
      });
      return;
    }

    // Check invite expiration
    const expireDate = new Date(invite.expires);
    const nowDate = new Date();
    if (nowDate > expireDate) {
      console.log('Invite has expired!');
      setLoading(this.$store, false);
      showConfirmWindow(this, 'generic.sorry', 'invites.expired', () => {
        this.$router.push({ name: 'login' });
      });
      return;
    }

    // Ask for acceptance
    await this.askForInviteAcceptance(invite);
  },
};
</script>

<style lang="scss" scoped></style>
