<template>
  <v-img
    v-on:click="goToLogin"
    class="mx-2"
    :src="logoUrl"
    max-height="80"
    max-width="250"
    contain
  ></v-img>
</template>
<script>
export default {
  name: 'Logo',
  data() {
    return {
      logoUrl: process.env.VUE_APP_LOGO_FILENAME,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.api.me !== null;
    },
    useTeamLogo() {
      if (!this.isLoggedIn) {
        if (this.$route.path === '/signup' && this.$route.query.inviteId) {
          return true;
        }
        return false;
      }
      const { type } = this.$store.state.api.me;
      return type === 'Team';
    },
  },
  mounted() {
    this.logoUrl = this.useTeamLogo
      ? process.env.VUE_APP_LOGO_TEAM
      : process.env.VUE_APP_LOGO_FILENAME;
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style scoped>
img {
  max-height: 50px;
}
</style>
