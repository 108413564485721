import { render, staticRenderFns } from "./Phq9Popup.vue?vue&type=template&id=ea699e2a&scoped=true&"
import script from "./Phq9Popup.vue?vue&type=script&lang=js&"
export * from "./Phq9Popup.vue?vue&type=script&lang=js&"
import style0 from "./Phq9Popup.vue?vue&type=style&index=0&id=ea699e2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea699e2a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardTitle,VDialog})
