<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.steps') }}
      </h2>

      <p
        class="pa-5 text-center subtitle"
        v-html="
          $t('reports.stepsResultHtml', {
            numberOfSteps: 5000,
          })
        "
      />
      <!-- Graph -->
      <section class="graph" ref="graph">
        <div class="yaxis">
          <p>500</p>
          <p>1000</p>
          <p>1500</p>
          <p>2000</p>
          <p>2500</p>
          <p>3000</p>
          <p>3500</p>
          <p>4000</p>
          <p>4500</p>
          <p>5000</p>
          <p>5500</p>
          <p>6000</p>
        </div>
        <div class="content">
          <div class="steps-goal" style="bottom: 365px">
            <p>step goal (5000)</p>
          </div>
          <div class="bars">
            <div style="height: 285px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>4000</span>
              </v-tooltip>
            </div>
            <div style="height: 365px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>5000</span>
              </v-tooltip>
            </div>
            <div style="height: 305px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>4213</span>
              </v-tooltip>
            </div>
            <div style="height: 315px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>4304</span>
              </v-tooltip>
            </div>
            <div style="height: 30px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>750</span>
              </v-tooltip>
            </div>
            <div style="height: 450px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>6000</span>
              </v-tooltip>
            </div>
            <div style="height: 290px">
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>3910</span>
              </v-tooltip>
            </div>
          </div>
          <div class="xaxis">
            <p>
              {{ $t('generic.weekdays.short.1') }} <br />
              5/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.2') }} <br />
              6/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.3') }} <br />
              7/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.4') }} <br />
              8/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.5') }} <br />
              9/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.6') }} <br />
              10/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.7') }} <br />
              11/9
            </p>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ReportSteps',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: larger;
}

$datesPos: 65px;
.graph {
  position: relative;
  display: flex;
  padding-bottom: $datesPos;
}

.yaxis {
  display: flex;
  flex-direction: column-reverse;
  > p {
    flex: 1;
  }
  p:first-child {
    margin-bottom: 0 !important;
  }
}
.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  .bars {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1;
    > div {
      position: relative;
      width: 100%;
      max-width: 30px;
      margin: 0 auto;
      flex: 1;
      border-radius: 50px;
      height: 80px;
      transition: all 0.3s;
      background-color: #5db6bf;
      border-color: #5db6bf;
      &:hover {
        background: #85d8e0 !important;
      }
      .bar-content {
        height: 100%;
      }
      @media (max-width: 500px) {
        max-width: 20px;
      }
    }
  }
}
.xaxis {
  display: flex;
  text-align: center;
  position: absolute;
  bottom: -$datesPos;
  width: 100%;
  > p {
    flex: 1;
    margin-bottom: 0;
  }
}

.steps-goal {
  position: absolute;
  z-index: 99;
  left: 0;
  width: 100%;
  p {
    margin-bottom: 0;
    padding-left: 4px;
    color: #35d8db;
    border-bottom: 2px solid #35d8db;
  }
}

.v-tooltip__content.menuable__content__active::before {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #a55dff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
