<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>
    <SimpleLocalePicker class="mgl-simple-locale" />
    <v-container fluid fill-height>
      <v-row class="mt-1">
        <v-col class="col-md-6 offset-md-3 mt-6">
          <v-card color="primaryDark" class="pb-10 mb-10">
            <h2 class="pa-5 d-flex align-center justify-center">
              {{ $t('resetPassword.setNewPassword') }}
            </h2>
            <p class="pa-5 d-flex align-center justify-center text-center">
              {{ $t('resetPassword.fill') }}
            </p>
            <v-form v-model="isPasswordFormValid">
              <div class="mx-5">
                <v-tooltip v-model="showTooltip" top color="secondary" allow-overflow>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :placeholder="$t('changePassword.newPassword')"
                      outlined
                      rounded
                      class="mgl-append-green mb-4"
                      color="secondary"
                      ref="newPassword"
                      type="password"
                      prepend-inner-icon="mdi-lock-outline"
                      validateOnInput
                      :rules="[
                        rules.required,
                        rules.minLength,
                        rules.uppercase,
                        rules.lowercase,
                        rules.symbol,
                        rules.number,
                        rules.writableSymbols,
                      ]"
                      required
                      hide-details
                      v-model="newPassword"
                      v-on="on"
                      :append-icon="allCriteriaFullfilled ? 'mdi-check' : ''"
                    ></v-text-field>
                  </template>
                  <ul class="mgl-pwd-list my-2">
                    <li
                      v-for="criteria in passwordCriterias"
                      :key="criteria.id"
                      class="d-flex align-center mb-1"
                    >
                      <v-chip
                        small
                        :color="criteria.fulfilled ? 'success' : 'white'"
                        :outlined="!criteria.fulfilled"
                        class="mr-2"
                        ><v-icon v-show="criteria.fulfilled" small>mdi-check</v-icon></v-chip
                      >
                      {{ criteria.text }}
                    </li>
                  </ul>
                </v-tooltip>
                <v-text-field
                  :placeholder="$t('changePassword.repeatPassword')"
                  outlined
                  rounded
                  class="mgl-append-green mb-4"
                  color="secondary"
                  name="repeatPassword"
                  ref="repeatPassword"
                  prepend-inner-icon="mdi-lock-outline"
                  type="password"
                  :rules="[rules.required, rules.matchPassword]"
                  required
                  validateOnInput
                  hide-details
                  v-model="repeatPassword"
                  v-model.trim="repeatPassword"
                  :append-icon="passwordMatch ? 'mdi-check' : ''"
                />
                <v-btn
                  rounded
                  depressed
                  block
                  x-large
                  color="secondary"
                  class="mb-2"
                  :disabled="!isPasswordFormValid"
                  @click="setPassword"
                  >{{ $t('account.changePassword') }}</v-btn
                >
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="text-center mt-15" color="background">
      <v-container>
        <v-row>
          <v-col>
            {{ $t('resetPassword.needHelp') }}
            <a class="pl-2" href="mailto:support@mindforcegamelab.com">
              <u>{{ $t('resetPassword.contact') }}</u>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import axios from 'axios';
import Logo from '../components/Logo.vue';
import { displayMessage } from '../utils/loading';
import { ALLOWED_CHARACTERS_REGEX } from '../utils/constants';
import SimpleLocalePicker from '../components/SimpleLocalePicker.vue';

export default {
  name: 'NewPassword',
  components: {
    SimpleLocalePicker,
    Logo,
  },
  data() {
    return {
      tempLicense: '',
      currentPassword: '',
      changePassword: '',
      newPassword: '',
      repeatPassword: '',
      isPasswordFormValid: false,
      token: '',
      rules: {
        required: (v) => !!v || this.$i18n.t('validation.fieldNotEmpty'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('validation.emailInvalid'),
        minLength: (v) => v.length >= 8 || this.$i18n.t('validation.minCharacters'),
        uppercase: (v) => /[A-Z]/.test(v) || this.$i18n.t('validation.uppercase'),
        lowercase: (v) => /[a-z]/.test(v) || this.$i18n.t('validation.lowercase'),
        symbol: (v) =>
          /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v) || this.$i18n.t('validation.symbol'),
        number: (v) => /\d/.test(v) || this.$i18n.t('validation.number'),
        matchPassword: () =>
          this.newPassword === this.repeatPassword || this.$i18n.t('validation.passwordNotMatch'),
        writableSymbols: (v) =>
          !ALLOWED_CHARACTERS_REGEX.test(v) || this.$i18n.t('validation.writableSymbols'),
      },
      showTooltip: false,
      currentPasswordValidated: false,
    };
  },
  mounted() {
    const { token } = this.$route.query;
    if (!token) {
      // If there is no token, automatic routing to 404
      this.$router.push({ name: '404' });
    }
    this.token = token;
  },
  computed: {
    user() {
      return this.$store.state.api.me;
    },
    passwordMatch() {
      return this.newPassword.length > 1 && this.newPassword === this.repeatPassword;
    },
    allCriteriaFullfilled() {
      // @ts-ignore
      return this.passwordCriterias.every((criteria) => criteria.fulfilled);
    },
    passwordCriterias() {
      return [
        {
          text: this.$i18n.t('signup.passwordCriterias.uppercase'),
          fulfilled: /[A-Z]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.lowercase'),
          fulfilled: /[a-z]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.number'),
          fulfilled: /\d/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.symbol'),
          fulfilled: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.newPassword),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.minLength'),
          fulfilled: this.newPassword.length >= 6,
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.writableCharacters'),
          fulfilled: !ALLOWED_CHARACTERS_REGEX.test(this.newPassword),
        },
      ];
    },
  },
  methods: {
    // updating new password with post request
    async setPassword() {
      const changePasswordResult = await axios(`${process.env.VUE_APP_API}/recover/change`, {
        method: 'POST',
        headers: {
          Accept: 'text/plain; charset=utf-8',
          'Content-Type': 'application/problem+json; charset=utf-8',
        },
        data: JSON.stringify({
          token: this.token,
          password: this.newPassword,
        }),
      });
      const jsonResponse = await changePasswordResult.data;
      if (jsonResponse.result === true) {
        this.$router.push({ name: 'loginsmall' });
      } else {
        displayMessage(this.$store, this.$i18n.t('resetPassword.tokenNotValid'), 'warning');
      }
    },
  },
};
</script>
