import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import _ from 'lodash';
import password from 'secure-random-password';

/**
 *@typedef {{username: string, password?:string, userPoolId: string, clientId:string, temporaryPassword?: string}} CognitoUserCredentials
 *@typedef {{accessToken: string, userId: string, clientId: string, exp: number, iss: string}} CognitoUserTokenData
 */

const storageMap = new Map();
const memStorage = {
  setItem: (key, value) => {
    return storageMap.set(key, value);
  },
  getItem: (key) => {
    return storageMap.get(key);
  },
  removeItem: () => {},
  clear: () => {},
};

/**
 * Get Authentication details for cognito user
 * @param {CognitoUserCredentials} cognitoUser
 * @param {object} [metadata]
 * @returns {AuthenticationDetails}
 */
function getAuthDetailsForPassChange(cognitoUser, metadata) {
  const authenticationData = {
    Username: cognitoUser.username,
    Password: cognitoUser.temporaryPassword,
  };
  if (metadata) {
    authenticationData.ClientMetadata = getClientMetaData(metadata);
  }
  return new AuthenticationDetails(authenticationData);
}

/**
 * Get cognito user object from credentials
 * @param {CognitoUserCredentials} cognitoUser
 * @returns {CognitoUser}
 */
function getCognitoUserObj(cognitoUser) {
  const userPool = new CognitoUserPool({
    UserPoolId: cognitoUser.userPoolId,
    ClientId: cognitoUser.clientId,
    Storage: memStorage,
  });
  const userData = {
    Username: cognitoUser.username,
    Pool: userPool,
  };

  return new CognitoUser(userData);
}

/**
 * Changes password for user
 * @param {CognitoUserCredentials} newCognitoUser
 * @param {object} [metadata]
 * @returns {Promise<string>}
 */
export async function changePassword(newCognitoUser, metadata) {
  // Generate a good password
  const newPassword = password.randomPassword({
    length: 16,
    characters: [password.lower, password.upper, password.symbols, password.digits],
  });
  const cognitoUser = getCognitoUserObj(newCognitoUser);
  const authenticationDetails = getAuthDetailsForPassChange(newCognitoUser, metadata);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onFailure: (err) => {
        console.log('Failed authentication', err);
        reject(err);
      },
      newPasswordRequired: () => {
        cognitoUser.completeNewPasswordChallenge(newPassword, [], {
          onSuccess: () => resolve(newPassword),
          onFailure: reject,
        });
      },
      onSuccess: () => {
        cognitoUser.changePassword(newCognitoUser.password, newPassword, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(newPassword);
          }
        });
      },
    });
  });
}

/**
 * Get client meta data from metadata object
 * @param {object} metadata
 * @returns {object}
 */
function getClientMetaData(metadata) {
  const clientMetadata = {};
  for (const prop in metadata) {
    if (Object.prototype.hasOwnProperty.call(metadata, prop)) {
      clientMetadata[prop] = metadata[prop].toString();
    }
  }
  return clientMetadata;
}

/**
 * Get Cognito user pool object from credentials
 * @param {CognitoUserCredentials} cognitoUserWithPool
 * @returns {CognitoUserPool}
 */
function getCognitoUserPool(cognitoUserWithPool) {
  const { userPoolId, clientId } = cognitoUserWithPool;
  return new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: clientId,
    Storage: memStorage,
  });
}

/**
 * Get Cognito user object from credentials
 * @param {CognitoUserCredentials} cognitoUserWithPool
 * @returns {CognitoUser}
 */
function getCognitoUser(cognitoUserWithPool) {
  const userPool = getCognitoUserPool(cognitoUserWithPool);
  const userData = {
    Username: cognitoUserWithPool.username,
    Pool: userPool,
  };
  return new CognitoUser(userData);
}

/**
 * Log user out
 * @param {CognitoUserCredentials} cognitoUserWithPool
 */
export function logout(cognitoUserWithPool) {
  const cognitoUser = getCognitoUser(cognitoUserWithPool);
  cognitoUser.signOut();
}

/**
 * Login user and return user credentials object
 * @param {CognitoUserCredentials} cognitoUserWithPool
 * @param {object} [metadata]
 * @returns {Promise<CognitoUserTokenData>}
 */
export function login(cognitoUserWithPool, metadata) {
  const cognitoUser = getCognitoUser(cognitoUserWithPool);
  const authenticationData = {
    Username: cognitoUserWithPool.username,
    Password: cognitoUserWithPool.password,
  };
  if (metadata) {
    authenticationData.ClientMetadata = getClientMetaData(metadata);
  }

  const authenticationDetails = new AuthenticationDetails(authenticationData);
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          userId: _.get(session, 'accessToken.payload.sub', ''),
          clientId: _.get(session, 'accessToken.payload.client_id'),
          exp: _.get(session, 'accessToken.payload.exp'),
          iss: _.get(session, 'accessToken.payload.iss'),
        });
      },
      onFailure: reject,
    });
  });
}
