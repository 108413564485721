<template>
  <div>
    <v-container fluid fill-height class="pb-0 d-flex justify-center">
      <v-row>
        <v-col class="text-center pb-0">
          <h2>{{ $t('reports.header') }}</h2>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col class="text-center col-10 offset-1 col-md-4 offset-md-4">
          {{ $t('reports.description') }}
        </v-col>
      </v-row>

      <!-- PREVIOUS CODE -->

      <v-container>
        <v-row>
          <v-row v-if="reports"
            ><v-col class="text-center pb-0">{{ $t('reports.loading') }}</v-col></v-row
          >
          <v-row v-else>
            <v-col v-if="reportOption === 'choose'" class="px-0">
              <v-chip-group multiple class="px-2 mgl-reminder-chips">
                <v-chip
                  v-for="report in reports"
                  :key="report.name"
                  :value="report.name"
                  active-class="secondary"
                  class="ma-0"
                >
                  {{ report }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
      <!-- After select -->
      <v-col v-for="(report, i) in reports" :key="i" cols="12">
        <v-card text rounded color="primaryDark" flat>
          <v-toolbar flat color="primary">
            <v-toolbar-title class="subtitle-1 font-weight-bold">
              {{ $t('generic.week') }} {{ report.week }}</v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="d-flex justify-space-between">
            <v-icon>mdi-tree-outline</v-icon>
            <div>{{ adherencePercent(report) }}% {{ $t('reports.doses_taken') }}</div>
            <div>
              <router-link
                :to="{ path: 'details', query: { startDate: report.startDate } }"
                append
                >{{ $t('reports.detail') }}</router-link
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- datalist selection of reports -->
      <v-select
        style="max-width: 500px; margin: auto"
        flat
        :items="reportOptions"
        :placeholder="$t('reports.reportlist')"
        dense
        filled
        background-color="primary"
        color="primary"
        class="pa-5"
        solo
      ></v-select>
    </v-container>
    <!-- Medication section -->
    <ReportMedication />
    <!-- Mood section -->
    <ReportMood />
    <!-- Meal section -->
    <ReportMeal />
    <!-- Sleep section -->
    <ReportSleep />
    <!-- Steps section -->
    <ReportSteps />
    <!-- PDF button -->
    <v-row class="pa-5">
      <v-col class="col-md-4 offset-md-4 text-center">
        <v-btn
          color="secondary"
          v-on:click="PDFdownload"
          rounded
          depressed
          x-large
          class="px-15 uppercase"
        >
          {{ $t('reports.downloadPDF') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { FIG_APPID } from '../utils/constants';
import ReportMedication from './components/ReportMedication.vue';
import ReportMood from './components/ReportMood.vue';
import ReportMeal from './components/ReportMeal.vue';
import ReportSleep from './components/ReportSleep.vue';
import ReportSteps from './components/ReportSteps.vue';
import { displayError } from '../utils/loading';
import { anonymousApiDispatch } from '../utils/dispatch';

const parser = require('cron-parser');

export default {
  name: 'Reports',
  components: {
    ReportMedication,
    ReportMood,
    ReportMeal,
    ReportSleep,
    ReportSteps,
  },
  data() {
    return {
      reportOptions: [
        'Jan 1 - Jan 7, 2023 (Week 1)',
        'Jan 1 - Jan 7, 2023 (Week 3)',
        'Jan 1 - Jan 7, 2023 (Week 4)',
        'Jan 1 - Jan 7, 2023 (Week 5)',
        'Jan 1 - Jan 7, 2023 (Week 6)',
      ],
      reportOption: 'choose',
      reports: undefined,
    };
  },
  mounted() {
    console.log('Mounted');
  },
  methods: {
    showMedicationValue() {
      const medicationPercentage = this.$refs.medication.value;
      this.medicationPercentage = medicationPercentage;
    },
    adherencePercent(report) {
      console.log('adherencePercent');
      if (!this.reports) return 0;
      const scheduledDoses = this.nrOfScheduledDoses(report);
      console.log(
        `week: ${report.week}, adherence: ${report.adherenceValue}, scheduled: ${scheduledDoses}`,
      );
      return (report.adherenceValue / scheduledDoses) * 100;
    },
    nrOfScheduledDoses(report) {
      const { doses } = this.$store.state.api;

      let count = 0;
      const startDate = new Date(report.startDate);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 7);
      const options = {
        currentDate: startDate,
        endDate,
        iterator: true,
      };

      doses.forEach((dose) => {
        console.log(`cron: ${dose.schedule}`);
        console.log(`${JSON.stringify(options)}`);
        const interval = parser.parseExpression(dose.schedule, options);
        let obj;

        do {
          try {
            obj = interval.next();
            console.log(`Dose scheduled at ${JSON.stringify(obj)}`);
            count += 1;
          } catch (e) {
            break;
          }
          // @ts-ignore
        } while (!obj.done);
      });
      return count;
    },
    async fetchReports() {
      try {
        this.reports = await anonymousApiDispatch(this.$store, this.$router, 'getWeeklyReports', {
          appId: FIG_APPID,
          playstart: this.$store.state.api.me.playstart,
        });
      } catch (error) {
        displayError(this.$store, error.message);
      }
    },
    PDFdownload() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--link::before {
  background-color: red;
}
.theme--light.v-list-item:hover::before {
  opacity: 0.64;
}
</style>
