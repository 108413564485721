/**
 * @typedef {{ [key: string]: string | (string | null)[] | null | undefined }} StringDictionary
 */

/**
 * Get target route on login
 * @param {import("vue-router").Route} route
 * @param {import("vue-router").default} router
 * @param {?} user
 * @param {string} [inviteId]
 * @returns {{name: string, query: StringDictionary}}
 */
export function getTargetRoute(route, router, user, inviteId) {
  let targetRoute = {
    name: '404',
    query: null,
  };
  const { query } = route;
  const { to } = query;
  if (to !== undefined && typeof to === 'string') {
    const desiredRoute = router.resolve({
      name: to,
      query,
    });
    // Only redirect user to route if it exists
    if (desiredRoute.resolved.matched.length > 0) {
      targetRoute = {
        name: to,
        query,
      };
    }
  } else {
    targetRoute.name = user.emailVerified ? 'home' : 'verifyemail';
    if (user.emailVerified && (inviteId || user.type === 'Team')) {
      targetRoute.name = 'team';
    }
  }
  return targetRoute;
}

export default getTargetRoute;
