<template>
  <div>
    <v-container>
      <v-row>
        <v-col class="text-center">
          <h2>{{ $t('start.welcome') }}</h2>
        </v-col>
      </v-row>
      <!-- Onboarded -->
      <v-row v-if="onboarded && hasShortcuts">
        <v-col class="col-md-6 offset-md-3 mt-6">
          <v-card
            text
            rounded
            color="primary"
            class="text-center pa-5"
            flat
            style="max-width: 500px; margin: auto"
          >
            <p class="pb-5">{{ $t('steps.usefulShortcuts') }}</p>
            <div v-if="hasLicense && medicationRequired" class="mt-5">
              <router-link to="/medications">
                <v-btn
                  style="width: 400px"
                  class="initial truncated"
                  color="secondary"
                  depressed
                  rounded
                  x-large
                  >{{ $t('steps.changeMedicationSchedule') }}</v-btn
                >
              </router-link>
            </div>
            <div v-if="showLicense" class="mt-5">
              <router-link to="/licenses">
                <v-btn
                  style="width: 400px"
                  rounded
                  depressed
                  x-large
                  color="secondary"
                  class="initial truncated"
                  >{{ $t('steps.updateLicense') }}</v-btn
                >
              </router-link>
            </div>
            <div v-if="isPlaytient && this.phqEnabled" class="mt-5">
              <router-link to="/phq9-start">
                <v-btn
                  style="width: 400px"
                  rounded
                  depressed
                  x-large
                  color="secondary"
                  class="initial truncated"
                  >{{ $t('steps.selfassessment') }}</v-btn
                >
              </router-link>
            </div>
            <div v-if="hasLicense || !this.isPlaytient" class="mt-5">
              <router-link to="/team">
                <v-btn
                  style="width: 400px"
                  rounded
                  depressed
                  x-large
                  color="secondary"
                  class="initial truncated"
                  >{{ $t('steps.teams') }}</v-btn
                >
              </router-link>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Not onboarded -->
      <v-row v-if="!onboarded">
        <v-col class="col-md-6 offset-md-3">
          <v-timeline align-top dense class="mgl-timeline">
            <v-timeline-item
              v-for="(step, i) in steps"
              :key="i"
              :icon="currentStep - 1 > i ? 'mdi-check' : ''"
              :color="stepColor(i)"
              fill-dot
              class=""
            >
              <v-card
                rounded
                flat
                color="primary"
                min-height="78px"
                class="d-flex justify-center flex-column"
              >
                <v-card-text style="display: flex">
                  <h2
                    :style="{
                      'margin-right': '70px',
                      'margin-left': '-130px',
                      color: 'white',
                      'padding-top': currentStep - 1 <= i ? '10px' : '0',
                    }"
                  >
                    Step {{ i + 1 }}
                  </h2>
                  <div>
                    {{ currentStep - 1 > i ? step.textCompleted : step.text }}
                  </div>
                </v-card-text>
                <div class="mx-5 pb-5 d-flex justify-space-between" v-if="currentStep - 2 < i">
                  <v-btn
                    rounded
                    depressed
                    :block="!step.skippable"
                    :color="currentStep > i ? 'secondary' : ''"
                    :disabled="currentStep <= i"
                    @click="step.method"
                    class="initial truncated"
                  >
                    {{ step.buttonText }}
                  </v-btn>
                  <v-btn
                    v-if="step.skippable"
                    rounded
                    depressed
                    :color="currentStep > i ? 'secondary' : ''"
                    :disabled="currentStep <= i"
                    @click="step.skip"
                    class="initial truncated"
                  >
                    {{ $t('steps.skip') }}
                  </v-btn>
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getDownloadPageUrl } from '../utils/licenses';
import { setUserInfo } from '../api/users';
import { postEvent } from '../api/unityAnalytics';
import { isFeatureEnabled } from '../utils/config';

export default {
  name: 'Home',
  data() {
    return {
      currentStep: 1,
      phqEnabled: true,
    };
  },
  methods: {
    stepColor(index) {
      let stepColor = 'primary';
      if (this.currentStep > index) {
        stepColor = 'secondary';
      }
      if (this.currentStep - 1 > index) {
        stepColor = 'success';
      }
      return stepColor;
    },
    getCurrentStep() {
      const stepCount = this.hasEvergreenLicense ? 3 : 4;
      const { state } = this.$store;
      if (state.doneWithTeam || state.api.invites.length > 0 || state.api.team.length > 0) {
        return stepCount;
      }
      if (state.api.doses.length > 0 || !state.api.me.medicationRequired) {
        return stepCount - 1;
      }
      if (state.api.me.licenses.length > 0) {
        return stepCount - 2;
      }
      return 1;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.api.me !== null;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    hasLicense() {
      return this.isLoggedIn ? this.$store.getters['api/hasValidLicense'] : false;
    },
    hasEvergreenLicense() {
      return this.hasLicense && this.$store.getters['api/hasEvergreenLicense'];
    },
    showLicense() {
      return this.isPlaytient && !this.license && !this.hasEvergreenLicense;
    },
    license() {
      return this.$store.state.api.me.licenses[0];
    },
    isPlaytient() {
      if (!this.isLoggedIn) {
        return false;
      }
      const { type } = this.$store.state.api.me;
      return type === 'Playtient' || type === 'MGL';
    },
    onboarded() {
      const { me } = this.$store.state.api;
      return me == null ? false : me.onboarded;
    },
    medicationRequired() {
      return this.$store.state.api.me.medicationRequired;
    },
    hasShortcuts() {
      return (
        (this.hasLicense && this.medicationRequired) ||
        this.showLicense ||
        (this.isPlaytient && this.phqEnabled) ||
        this.hasLicense ||
        !this.isPlaytient
      );
    },
    // eslint-disable-next-line max-lines-per-function
    steps() {
      const onEvergreenLicense = this.hasEvergreenLicense;
      const stepArr = [
        {
          text: this.$i18n.t('steps.license'),
          textCompleted: this.$i18n.t('steps.licenseCompleted'),
          buttonText: this.$i18n.t('steps.licenseLinkText'),
          method: () => this.$router.push({ name: 'licenses' }),
        },
        {
          text: this.$i18n.t('steps.medications'),
          textCompleted:
            this.$store.state.api.me && this.$store.state.api.me.medicationRequired
              ? this.$i18n.t('steps.medicationsCompleted')
              : this.$i18n.t('fig.medication.optOutPlanSet'),
          buttonText: this.$i18n.t('steps.medicationsLinkText'),
          route: '/medications',
          method: () => this.$router.push({ name: 'medications' }),
        },
        {
          text: this.$i18n.t('steps.team'),
          textCompleted: this.$i18n.t('steps.teamCompleted'),
          buttonText: this.$i18n.t('steps.teamLinkText'),
          skippable: true,
          route: '/team',
          method: () => {
            this.$router.push({ name: 'team' });
          },
          skip: async () => {
            await postEvent(this.$store, 'OnboardingStepCompleted', {
              onboardingStep: 'team',
              stepSkipped: true,
            });
            this.currentStep += 1;
          },
        },
        {
          text: this.$i18n.t(onEvergreenLicense ? 'steps.backToGame' : 'steps.download'),
          buttonText: this.$i18n.t(
            onEvergreenLicense ? 'steps.backToGameLinkText' : 'steps.downloadLinkText',
          ),
          route: '/download',
          method: async () => {
            const downloadUrl = getDownloadPageUrl(this.$store, this.$i18n);
            window.open(downloadUrl, '_blank');
            await setUserInfo(this.$store, this.$router, { onboarded: true });
            await postEvent(this.$store, 'OnboardingStepCompleted', {
              onboardingStep: 'download',
            });
          },
        },
      ];
      if (onEvergreenLicense) {
        return stepArr.splice(1);
      }
      return stepArr;
    },
  },
  async mounted() {
    this.show = true;
    if (this.$store.state.api.me == null) {
      return;
    }
    this.currentStep = this.getCurrentStep();
    this.phqEnabled = await isFeatureEnabled(this.$store, 'PHQ');
  },
};
</script>
