<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>
    <SimpleLocalePicker class="mgl-simple-locale" />

    <v-container fluid fill-height>
      <v-row class="mt-1">
        <v-col class="col-md-6 offset-md-3 mt-6">
          <v-card class="pb-10 text-center" color="primaryDark">
            <h2 class="pa-5 d-flex align-center justify-center">
              {{ $t('resetPassword.passwordReset') }}
            </h2>
            <p class="pa-5 d-flex align-center justify-center text-center">
              {{ $t('resetPassword.successfulReset') }}
            </p>
            <v-row>
              <v-col class="col-md-4 offset-md-4 text-center">
                <v-btn
                  color="secondary"
                  v-on:click="goToLogin"
                  rounded
                  depressed
                  x-large
                  class="px-15 uppercase"
                >
                  {{ $t('login.login') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="text-center mt-15" color="background">
      <v-container>
        <v-row>
          <v-col>
            {{ $t('resetPassword.needHelp') }}
            <a class="pl-2" href="mailto:support@mindforcegamelab.com">
              <u>{{ $t('resetPassword.contact') }}</u>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import SimpleLocalePicker from '../components/SimpleLocalePicker.vue';
import Logo from '../components/Logo.vue';

export default {
  name: 'LoginSmall',
  components: {
    SimpleLocalePicker,
    Logo,
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
