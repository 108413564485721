import { login, logout, changePassword } from './cognito';
import { envelopeEncrypt, envelopeDecrypt } from './crypto';
import { getEpochSeconds, getISODateString } from './datetime';
import signOut from './auth';
import { apiDispatch, anonymousApiDispatch } from './dispatch';

/**
 * @typedef {{store: import("vuex").Store, router: import("vue-router").default}} VueApiContext
 * @typedef {{accessToken: string, clientId: string, exp: number, iss: string, userId: string, id?: string
 * type?: string, birthYear?: string, gender?: string, diagnosis?: string, groupIds?: string, diagnosisDate?: null|string, sensorTemplateGroupId?: string}} AnonymousFullUserInfo
 */

const USER_INFO_KEY = 'anonymousUser';
const JWT_VALIDITY_MARGIN_SECONDS = 30;

/**
 * Store anonymous user info in local storage
 * @param {AnonymousFullUserInfo} userInfo user info
 */
function setAnonymousUserInfo(userInfo) {
  localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
}

/**
 * Get anonymous user info from local storage
 * @returns {AnonymousFullUserInfo|null}
 */
function getUserInfo() {
  const userInfoStr = localStorage.getItem(USER_INFO_KEY);
  if (!userInfoStr) {
    return null;
  }
  return JSON.parse(userInfoStr);
}

/**
 * Check if user credentials has expired
 * @param {import("./cognito").CognitoUserTokenData} userTokenData
 * @returns {boolean}
 */
function hasJwtTokenExpired(userTokenData) {
  if (!userTokenData || !userTokenData.exp) {
    return true;
  }
  const expiryEpoch = userTokenData.exp;
  return expiryEpoch && expiryEpoch < getEpochSeconds(new Date()) - JWT_VALIDITY_MARGIN_SECONDS;
}

/**
 * Clears local storage keys
 */
export function clearSessionKeys() {
  clearCognitoLocalStorage();
  localStorage.removeItem(USER_INFO_KEY);
}

/**
 * Clears local storage for anonymous user
 */
function clearCognitoLocalStorage() {
  const userInfo = getUserInfo();
  if (!userInfo) {
    return;
  }
  const { clientId, iss } = userInfo;
  const lastUserId = localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`,
  );
  if (!lastUserId) {
    return;
  }

  const lastSlashIssIndex = iss.lastIndexOf('/');
  logout({
    userPoolId: iss.substr(lastSlashIssIndex + 1),
    clientId,
    username: lastUserId,
  });
}

/**
 * Logging in anonymous
 * @param {import("./cognito").CognitoUserCredentials} credentials
 * @returns {Promise<void>}
 */
async function loginAnonymous(credentials) {
  console.log('Logging in anonymously...');
  const userInfo = await login(credentials);
  setAnonymousUserInfo(userInfo);
}

/**
 * Loads anonymous user info
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @returns {Promise<AnonymousFullUserInfo>}
 */
async function loadAnonymousUserInfo(store, router) {
  let userInfo = getUserInfo();
  console.log('Getting anonymous user info...');
  let anonymousUserInfo;
  try {
    [anonymousUserInfo] = await anonymousApiDispatch(store, router, 'getUserInfo');
  } catch (err) {
    const { response } = err;
    if (response && response.status === 404) {
      throw new Error('No anonymous user info');
    }
    throw err;
  }
  if (anonymousUserInfo === undefined) {
    throw new Error('No anonymous user info');
  }
  setAnonymousUserInfo({ ...userInfo, ...anonymousUserInfo });
  userInfo = getUserInfo();
  const { sensorTemplateGroupId } = userInfo;
  if (!sensorTemplateGroupId) {
    const senosorGroup = await anonymousApiDispatch(store, router, 'createSensorGroup', {
      platform: 'web',
      anonymousUserId: userInfo.userId,
      startDate: getISODateString(new Date()),
    });
    setAnonymousUserInfo({ ...userInfo, sensorTemplateGroupId: senosorGroup.id });
  }
  return userInfo;
}

/**
 * Returns anonymous user info using context
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @returns {Promise<?>}
 */
export async function getAnonymousAuth(store, router) {
  const userInfo = getUserInfo();
  if (userInfo && !hasJwtTokenExpired(userInfo)) {
    return userInfo;
  }
  // TODO: make this work with auth refresh?
  await signOut(store, router, true);
  throw new Error('Sign out on inactivity');
}

/**
 * Initializes anonymous user with credentials
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {CryptoKeyPair} rsaKey
 * @param {string} anonymousUserCredentials
 * @param {AnonymousFullUserInfo} [anonymousUserInfo]
 * @returns {Promise<AnonymousFullUserInfo>}
 */
export async function initAnonymousUser(
  store,
  router,
  rsaKey,
  anonymousUserCredentials,
  anonymousUserInfo,
) {
  // If there are no credentials -> Create a new user
  if (!anonymousUserCredentials && anonymousUserInfo) {
    await createAnonymousUser(store, router, rsaKey, anonymousUserInfo);
    return loadAnonymousUserInfo(store, router);
  }

  const credentials = await getAnonymousUserCredentials(rsaKey, anonymousUserCredentials);

  await loginAnonymous(credentials);
  return loadAnonymousUserInfo(store, router);
}

/**
 * Get anonymous user credentials from encrypted string
 * @param {CryptoKeyPair} rsaKey
 * @param {string} anonymousUserCredentials encrypted credentials
 * @returns {Promise<import("./cognito").CognitoUserCredentials>}
 */
async function getAnonymousUserCredentials(rsaKey, anonymousUserCredentials) {
  try {
    const credentials = await envelopeDecrypt(rsaKey.privateKey, anonymousUserCredentials);
    const parsedCredentials = JSON.parse(credentials);
    return parsedCredentials;
  } catch (err) {
    console.log('Failed to decrypt anonymous user. Create new user', err.message);
    return getAnonymousUserCredentials(rsaKey, anonymousUserCredentials);
  }
}

/**
 * Creates an anonymous user with supplied information
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {CryptoKeyPair} rsaKey
 * @param {AnonymousFullUserInfo} anonymousUserInfo
 * @returns {Promise<import("./cognito").CognitoUserCredentials>}
 */
async function createAnonymousUser(store, router, rsaKey, anonymousUserInfo) {
  console.log('Creating new anonymous user!');
  const anonymousUser = await apiDispatch(store, router, 'createAnonymousUser');
  const password = await changePassword(anonymousUser);
  const credentials = {
    ...anonymousUser,
    password,
  };
  const encryptedCredentials = await envelopeEncrypt(rsaKey.publicKey, JSON.stringify(credentials));

  await loginAnonymous(credentials);

  await Promise.all([
    apiDispatch(store, router, 'registerAnonymousUser', {
      encryptedCredentials,
    }),
    anonymousApiDispatch(store, router, 'register', {
      userInfo: anonymousUserInfo,
    }),
  ]);
  return credentials;
}
