<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>

    <SimpleLocalePicker class="mgl-simple-locale" />
    <v-slide-x-transition group>
      <v-main key="1" v-show="show">
        <v-form @submit.prevent="logIn" ref="loginForm">
          <v-container fluid fill-height>
            <v-row class="mt-10">
              <v-col class="col-10 offset-1 col-md-4 offset-md-4">
                <v-text-field
                  name="email"
                  :placeholder="$t('login.email')"
                  outlined
                  rounded
                  color="accent"
                  prepend-inner-icon="mdi-email-outline"
                  class="mgl-prepend-absolute mgl-noborder"
                  type="text"
                  :rules="[rules.email]"
                  validate-on-blur
                  v-model="email"
                  background-color="primary"
                  @keydown.space.prevent=""
                  @paste="handlePaste"
                  @input="email = email.replace(/\s+/g, '').toLowerCase()"
                ></v-text-field>
                <v-text-field
                  name="password"
                  :placeholder="$t('login.password')"
                  outlined
                  rounded
                  prepend-inner-icon="mdi-lock-outline"
                  color="accent"
                  type="password"
                  hide-details
                  v-model.trim="password"
                  :rules="[rules.notEmpty]"
                  background-color="primary"
                  class="mb-4 mgl-prepend-absolute mgl-noborder"
                >
                </v-text-field>
                <router-link to="/resetpassword"
                  ><p class="float-right white--text text-decoration-underline">
                    {{ $t('login.forgotPassword') }}
                  </p></router-link
                >
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col class="col-10 offset-1 col-md-4 offset-md-4">
                <v-btn x-large rounded block color="secondary" type="submit">{{
                  $t('login.login')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-main>

      <v-footer key="2" v-show="show" class="text-center" color="background">
        <v-container>
          <v-row>
            <v-col>
              {{ $t('login.notRegistered') }}
              <router-link
                :to="{ name: 'signup', query: this.computedQuery }"
                class="pl-2 text-uppercase"
              >
                {{ $t('login.createAccount') }}
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import Logo from '../components/Logo.vue';
import SimpleLocalePicker from '../components/SimpleLocalePicker.vue';
import { initRsaKey } from '../utils/crypto';
import { initAnonymousUser } from '../utils/anonymousAuth';
import { apiDispatch } from '../utils/dispatch';
import { displayError, setLoading, setLoadingText } from '../utils/loading';
import { getTargetRoute } from '../utils/routeHandler';

export default {
  components: {
    Logo,
    SimpleLocalePicker,
  },
  data() {
    return {
      email: '',
      password: '',
      inviteId: undefined,
      inviteObj: null,
      inviteEmail: undefined,
      show: false,
      rules: {
        notEmpty: (v) => v.length > 0 || this.$i18n.t('validation.fieldNotEmpty'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('validation.emailInvalid'),
      },
    };
  },
  computed: {
    computedQuery() {
      const { query } = this.$route;
      if ('inviteId' in query) {
        query.type = 'team';
      }
      return query;
    },
  },
  mounted() {
    this.show = true;
    if ('inviteId' in this.$route.query) {
      this.inviteId = this.$route.query.inviteId;
      this.inviteObj = this.$store.getters['api/getInviteObject'];
      this.inviteEmail = this.inviteObj.email;
      this.email = this.inviteEmail;
      console.log('Invite set');
    }
  },
  methods: {
    handlePaste(event) {
      const pastedText = event.clipboardData.getData('text/plain');
      const formattedText = pastedText.replace(/\s+/g, '').toLowerCase();
      this.email = formattedText;
      event.preventDefault();
    },
    forwardToStartPage(user) {
      this.$store.commit('invalid', false);
      const targetRoute = getTargetRoute(this.$route, this.$router, user, this.inviteId);
      this.$router.push(targetRoute);
      setLoading(this.$store, false);
    },
    // eslint-disable-next-line max-lines-per-function
    async logIn() {
      if (!this.$refs.loginForm.validate()) {
        return false;
      }
      setLoading(this.$store, true, 'login.title', 'login.authenticatingUser');
      try {
        await Auth.signIn(this.email, this.password);
        setLoadingText(this.$store, this.$i18n.t('loading.login.securingLogin'));
        const user = await apiDispatch(this.$store, this.$router, 'getMe');
        // Set default locale, if none
        user.language = user.language || 'sv';
        this.$i18n.locale = user.language;
        if (user.anonymousUserCredentials === null || user.privateKey === null) {
          await this.$root.$confirm(
            this.$i18n.t('signup.signup'),
            this.$i18n.t('signup.signupAgain'),
            {
              asHtml: true,
              disableCancel: true,
              actionButtonI18nKey: 'generic.okay',
            },
          );
          window.sessionStorage.setItem('signinPwd', this.password);
          this.$router.push({ name: 'signup', query: { stepId: '2' } });
          return false;
        }
        const rsaKey = await initRsaKey(
          this.$store,
          this.$router,
          this.password,
          user.privateKey,
          user.publicKey,
          user.salt,
          user.iv,
          false,
        );
        if (rsaKey === null) {
          throw new Error(this.$i18n.t('account.errorEncryptionKey').toString());
        }
        setLoadingText(this.$store, this.$i18n.t('loading.login.anonymisingData'));
        await initAnonymousUser(this.$store, this.$router, rsaKey, user.anonymousUserCredentials);
        setLoadingText(this.$store, this.$i18n.t('loading.almostDone'));
        await apiDispatch(this.$store, this.$router, 'getDoses', {
          privateKey: rsaKey.privateKey,
        });
        this.forwardToStartPage(user);
      } catch (error) {
        const { message } = error;
        if (message === 'No anonymous user info') {
          await this.$root.$confirm(
            this.$i18n.t('signup.signup'),
            this.$i18n.t('signup.signupAgain'),
            {
              asHtml: true,
              disableCancel: true,
              actionButtonI18nKey: 'generic.okay',
            },
          );
          window.sessionStorage.setItem('signinPwd', this.password);
          this.$router.push({ name: 'signup', query: { stepId: '2' } });
          return true;
        }
        displayError(this.$store, error.message);
      }
      return true;
    },
  },
};
</script>
