<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>
    <v-container fluid fill-height>
      <v-row>
        <v-col class="text-center mb-3">
          <h2>{{ $t('verifyEmail.title') }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center mb-3">
          <p v-html="$t('verifyEmail.subTextHtml', { email: this.email })"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card text rounded color="primary" flat class="pa-5">
            <v-card-text class="text-center">
              {{ $t('verifyEmail.checkButtonHeader') }}
            </v-card-text>
            <v-card-actions style="display: flex; flex-direction: column">
              <v-btn
                style="width: 400px; margin: auto"
                :disabled="requestInProgress"
                x-large
                depressed
                rounded
                color="secondary"
                class="text-center initial truncated"
                @click="checkAgain"
                >{{ $t('verifyEmail.checkButtonText') }}</v-btn
              >
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center mt-5 mb-3">
          <p v-html="$t('verifyEmail.resendButtonHeaderHtml')"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center mb-3">
          <a class="secondary--text text--lighten-5" @click="resendValidationLink">{{
            $t('verifyEmail.resendButtonText')
          }}</a>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col class="col-md-6 text-right">
          <p v-html="$t('verifyEmail.needMoreHelp')"></p>
        </v-col>
        <v-col class="col-md-6">
          <a href="mailto:support@mindforcegamelab.com" class="secondary--text">{{
            $t('verifyEmail.contactSupportText')
          }}</a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Logo from '../components/Logo.vue';
import signOut from '../utils/auth';
import { apiDispatch } from '../utils/dispatch';

export default {
  components: {
    Logo,
  },
  created() {
    this.email = this.$store.state.api.me.email;
  },
  data() {
    return {
      requestInProgress: false,
      email: undefined,
    };
  },
  methods: {
    async resendValidationLink() {
      if (this.requestInProgress) {
        return;
      }
      this.requestInProgress = true;
      console.log('Resend validation email!');
      try {
        await apiDispatch(this.$store, this.$router, 'resendEmailVerification');
        this.requestInProgress = false;
      } catch (err) {
        this.requestInProgress = false;
      }
    },
    async checkAgain() {
      if (this.requestInProgress) {
        return undefined;
      }
      this.requestInProgress = true;
      console.log('Checking validation again!');
      try {
        const user = await apiDispatch(this.$store, this.$router, 'getMe');
        this.requestInProgress = false;
        if (user.emailVerified) {
          this.$router.push({ name: 'home' });
        }
      } catch (err) {
        console.error('Error', err);
        this.requestInProgress = false;
        return signOut(this.$store, this.$router);
      }
      return true;
    },
  },
};
</script>
