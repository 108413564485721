import { apiDispatch } from '../utils/dispatch';
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';

/**
 * Api wrapper method to get Phq form data
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {boolean} [allowCached] if true state may be used instead of api
 * @returns {Promise<import("../store/modules/api").AssessmentForm[]>}
 */
export async function apiGetPhqFormData(store, router, allowCached) {
  if (allowCached && store.getters['api/hasFetchedPhqData']) {
    return store.getters['api/phqData'];
  }
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  return apiDispatch(store, router, 'getPhqFormData', {
    privateKey: rsaKeys.privateKey,
  });
}

/**
 * Api wrapper method to post Phq form data
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {import("../store/modules/api").AssessmentForm} formData
 * @returns {Promise<import("../store/modules/api").AssessmentForm>}
 */
export async function apiPostPhqFormData(store, router, formData) {
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  return apiDispatch(store, router, 'postPhqFormData', {
    publicKey: rsaKeys.publicKey,
    formData,
    privateKey: rsaKeys.privateKey,
  });
}
