import axios from 'axios';
import { getUuid } from '../utils/uuid';
import { getUserGroupIdCsv, getUserSessionId, getJournalDayIndex, getUser } from './me';
import { getAnonymousUserId } from './anonymousUser';
import { getFormattedISODateTimeString } from '../utils/datetime';

/**
 * Get event uuid from event params
 * @param {string} eventName
 * @param {string} anonymousUserId
 * @param {Date} eventTimestamp
 * @returns {Promise<string>}
 */
export async function getEventUuid(eventName, anonymousUserId, eventTimestamp) {
  const userIdHexStr = anonymousUserId.replace(/[-]/g, '');
  const eventEpochMs = eventTimestamp.getTime();
  return getUuid(`${eventName.toLowerCase()}-${userIdHexStr}-${eventEpochMs}`);
}

/**
 * Get analytics environment from user license groups ids
 * @param {string|null} groupIdCsv
 * @returns {string}
 */
export function getAnalyticsEnvironmentName(groupIdCsv) {
  const baseEnvName = process.env.VUE_APP_UNITY_ANALYTICS_ENV;
  if (!groupIdCsv) {
    return baseEnvName;
  }
  const userGroupId = getUserLicenseGroup(groupIdCsv);
  const validGroupIds = process.env.VUE_APP_UNITY_ANALYTICS_GROUPS.split(',');
  if (!validGroupIds.includes(userGroupId)) {
    return baseEnvName;
  }

  return `${baseEnvName}_${userGroupId}`;
}

/**
 * Get analytics url
 * Returns null if analytics is not enabled for this environment
 * @param {string} environmentName environment name
 * @returns {string|null}
 */
export function getAnalyticsCollectUrl(environmentName) {
  const projectId = process.env.VUE_APP_UNITY_PROJECT_ID;
  if (!projectId) {
    return null;
  }
  return `https://collect.analytics.unity3d.com/api/analytics/collect/v1/projects/${projectId}/environments/${environmentName}`;
}

/**
 * Get user group id from group id csv
 * @param {string} groupIdCsv group id csv
 * @returns {string}
 */
function getUserLicenseGroup(groupIdCsv) {
  const arr = groupIdCsv.split(',');
  return arr[arr.length - 1];
}

/**
 * Post unity analytics event
 * @param {import("vuex").Store} store
 * @param {string} name
 * @param {object} params
 * @param {(any)=>void} [onSuccess]
 * @param {(Error)=>void} [onError]
 * @returns {Promise<any>}
 */
export async function postEvent(store, name, params, onSuccess, onError) {
  if (process.env.VUE_APP_ALLOW_MGL_USER_ANALYTICS !== 'true') {
    const user = getUser(store);
    if (user.type === 'MGL') {
      console.log('MGL user analytics is disabled. Ignoring request.');
      return null;
    }
  }
  const anonymousUserId = getAnonymousUserId(store);
  const currentDate = new Date();
  const groupIdCsv = getUserGroupIdCsv(store);
  const environmentName = getAnalyticsEnvironmentName(groupIdCsv);
  const dayIndex = getJournalDayIndex(store);

  const eventData = {
    eventName: name,
    userID: anonymousUserId,
    sessionID: getUserSessionId(store),
    eventUUID: await getEventUuid(name, anonymousUserId, currentDate),
    eventTimestamp: getFormattedISODateTimeString(currentDate),
    eventParams: {
      platform: 'WEB',
      anonymousUserId,
      day: dayIndex !== undefined ? dayIndex : -1,
      env: environmentName,
      ...params,
    },
  };

  const collectUrl = getAnalyticsCollectUrl(environmentName);
  if (collectUrl === null || collectUrl === undefined || collectUrl.length === 0) {
    return Promise.resolve();
  }
  try {
    const response = await axios.post(collectUrl, eventData);
    const result = onSuccess ? onSuccess(response) : response;
    return Promise.resolve(result);
  } catch (error) {
    const result = onError ? onError(error) : null;
    if (result != null) {
      return Promise.reject(result);
    }
    return Promise.resolve();
  }
}
