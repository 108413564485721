<template>
  <div>
    <v-container fluid fill-height>
      <v-row>
        <v-col>
          <v-card text rounded color="primary" flat class="pa-5">
            <v-card-title>
              {{ $t('404.header') }}
            </v-card-title>
            <v-card-text>
              {{ $t('404.text') }}
            </v-card-text>
            <v-card-actions style="display: flex; flex-direction: column">
              <router-link to="/">
                <v-btn
                  x-large
                  depressed
                  block
                  rounded
                  color="secondary"
                  class="text-center initial truncated"
                  >{{ $t('404.back') }}</v-btn
                >
              </router-link>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
