import { apiDispatch } from '../utils/dispatch';

/**
 * Api wrapper method to get teams that we are part of
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {{appId: string}} payload payload
 * @param {boolean} [allowCached] if true state may be used instead of api
 * @returns {Promise<import("../store/modules/api").TeamMember[]>}
 */
export async function apiGetTeams(store, router, payload, allowCached) {
  if (allowCached && store.getters['api/hasFetchedTeamsData']) {
    return store.getters['api/teams'];
  }
  return apiDispatch(store, router, 'getTeams', payload);
}

/**
 * Api wrapper method to get our team
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {{appId: string, organizationId: string}} payload payload
 * @returns {Promise<(import("../store/modules/api").TeamMember|import("../store/modules/api").TeamMemberInvite)[]>}
 */
export async function apiGetTeam(store, router, payload) {
  return apiDispatch(store, router, 'getTeam', payload);
}

/**
 * Api wrapper to set user info
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {import("../store/modules/api").UserInfo} payload data to update
 * @returns {Promise<void>}
 */
export async function setUserInfo(store, router, payload) {
  return apiDispatch(store, router, 'setUserInfo', payload);
}
