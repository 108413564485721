/**
 * Get anonymous user id
 * @param {import("vuex").Store} store
 * @returns {string|null}
 */
export function getAnonymousUserId(store) {
  const anonymousInfo = getAnonymousUser(store);
  if (!anonymousInfo) {
    return null;
  }
  return anonymousInfo.id;
}

/**
 * Get full user info
 * @param {import("vuex").Store} store
 * @returns {import('../store/modules/anonymousApi').AnonymousInfo|null}
 */
export function getAnonymousUser(store) {
  return store.getters['anonymousApi/anonymousInfo'];
}
