<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <h2>{{ $t('download.header') }}</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getDownloadPageUrl } from '../utils/licenses';

export default {
  name: 'Download',
  data() {
    return {};
  },
  created() {
    const downloadUrl = getDownloadPageUrl(this.$store, this.$i18n);
    window.location.href = downloadUrl;
  },
};
</script>
