/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import snackbar from './modules/snackbar';
import api from './modules/api';
import anonymousApi from './modules/anonymousApi';

/**
 * @typedef {{refCount: number, isLoading:boolean, loadingText: string, loadingTitle: string, doneWithTeam: boolean, isInvalid: boolean,
 * api: import("./modules/api").ApiState, anonymousApi: import("./modules/anonymousApi").AnonymousState}} StoreType
 */

Vue.use(Vuex);

const vueStore = new Vuex.Store({
  modules: {
    snackbar,
    // @ts-ignore
    api,
    // @ts-ignore
    anonymousApi,
  },
  plugins: [
    createPersistedState({
      paths: ['api', 'anonymousApi'],
    }),
  ],
  state: {
    refCount: 0,
    isLoading: false,
    loadingText: '',
    loadingTitle: '',
    doneWithTeam: false,
    isInvalid: false,
  },
  mutations: {
    loading(state, isLoading) {
      if (isLoading) {
        state.refCount += 1;
        state.isLoading = true;
      } else if (state.refCount > 0) {
        state.refCount -= 1;
        state.isLoading = state.refCount > 0;
      } else {
        // Reset loading texts loading is completed
        state.loadingText = '';
        state.loadingTitle = '';
      }
    },
    setDoneWithTeam(state, doneWithTeam) {
      state.doneWithTeam = doneWithTeam;
    },
    loadingText(state, loadingText) {
      state.loadingText = loadingText;
    },
    loadingTitle(state, loadingTitle) {
      state.loadingTitle = loadingTitle;
    },
    invalid(state, isValid) {
      state.isInvalid = isValid;
    },
  },
});

/** @returns {import("vuex").Store<StoreType>} */
function getVueStore() {
  // @ts-ignore
  return vueStore;
}

export default getVueStore();
