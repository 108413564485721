<template>
  <div>
    <v-container fluid fill-height>
      <!-- Green result saved field -->

      <v-row>
        <v-col id="bannerBox" class="text-center mb-3" style="background-color: #46c55a">
          <h3 style="padding: 20px">{{ $t('PHQ9.resultSaved') }}</h3>
        </v-col>
      </v-row>
      <!-- Header -->
      <v-row>
        <v-col class="text-center mb-3">
          <h2 style="padding: 20px">{{ $t('PHQ9.title') }}</h2>
        </v-col>
      </v-row>
      <!-- Introduction -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 text-center">
          <p class="font-weight-light">{{ $t('PHQ9.wellDone') }}</p>
          <p class="font-weight-light">
            {{ $t('PHQ9.betterWellbeing') }}
          </p>
        </v-col>
      </v-row>
      <!-- Header past results -->
      <v-row style="padding-top: 50px">
        <v-col class="col-md-4 offset-md-4 text-center">
          <h2>{{ $t('PHQ9.pastResults') }}</h2>
        </v-col>
      </v-row>
      <!-- List of done PHQs -->
      <DonePHQ9s v-bind:resultPage="true" />
    </v-container>
  </div>
</template>

<script>
// import { apiGetPhqFormData } from '../api/storage';
import DonePHQ9s from '../components/DonePHQ9s.vue';

export default {
  name: 'PHQ9Finish',
  components: { DonePHQ9s },
  mounted() {
    setTimeout(() => {
      const bannerBox = document.getElementById('bannerBox');
      if (bannerBox) {
        bannerBox.style.display = 'none';
      }
    }, 5000);
  },
};
</script>
<style lang="scss">
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
