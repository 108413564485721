<template>
  <v-snackbar
    v-model="show"
    class="mgl-snackbar"
    :color="color"
    :timeout="timeout"
    fixed
    top
    tile
    app
  >
    <p class="text-center ma-0" v-html="message"></p>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 8000,
    };
  },
  created() {
    this.$store.watch(
      (state) => state.snackbar.snack,
      () => {
        const { snack } = this.$store.state.snackbar;
        const { message } = snack;
        if (message) {
          this.show = true;
          this.message = message;
          this.color = snack.color;
          this.$store.commit('snackbar/setSnack', {});
        }
      },
    );
  },
};
</script>
<style lang="scss">
.mgl-snackbar {
  .v-snack__wrapper {
    margin: 0;
  }
}
</style>
