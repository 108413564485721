<template>
  <v-container>
    <v-card style="max-width: 500px; margin: auto" color="primary" flat rounded class="pa-5">
      <h2 class="ma-0 text-center">
        {{ $t('reports.meals') }}
      </h2>

      <p
        class="pa-5 text-center subtitle"
        v-html="$t('reports.mealsLoggedHtml', { mealsPercentage: 80 })"
      />

      <!-- Graph -->
      <section class="graph">
        <div class="meals">
          <div class="yaxis">
            <div>{{ $t('reports.breakfast') }}</div>
            <div>{{ $t('reports.lunch') }}</div>
            <div>{{ $t('reports.dinner') }}</div>
          </div>
          <div class="checks-content">
            <div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
            </div>
            <div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="unchecked">
                  <v-icon dark>mdi-close</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div></div>
              </div>
            </div>
            <div>
              <div class="check">
                <div></div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div></div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
              <div class="check">
                <div class="checked">
                  <v-icon dark>mdi-check</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="xaxis">
          <p>
            {{ $t('generic.weekdays.short.1') }} <br />
            5/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.2') }} <br />
            6/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.3') }} <br />
            7/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.4') }} <br />
            8/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.5') }} <br />
            9/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.6') }} <br />
            10/9
          </p>
          <p>
            {{ $t('generic.weekdays.short.7') }} <br />
            11/9
          </p>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ReportMeal',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.meals {
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-bottom: 10px;
    > div:nth-child(odd) {
      background: #56369a;
    }
  }

  .yaxis {
    min-width: 80px;
    > div {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  .checks-content {
    display: flex;
    flex: 6;
    > div {
      padding: 10px 0;
      display: flex;
    }
    .check {
      margin: auto;
      width: 100%;
      max-width: 27px;
      height: 27px;
      margin: auto;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #301d59;
      }
      @media (max-width: 600px) {
        max-width: 25px;
        height: 25px;
      }
    }
    .checked {
      background: #5db6bf !important;
    }
    .unchecked {
      background: #9b6ed4 !important;
    }
  }
}

.xaxis {
  display: flex;
  text-align: center;
  padding-left: 80px;
  width: 100%;
  > p {
    flex: 1;
    margin-bottom: 0;
  }
}
</style>
