<template>
  <v-overlay v-if="isLoading">
    <v-card
      class="d-flex flex-column align-center justify-space-between"
      :color="loadingTitle ? 'primary' : 'transparent'"
      :elevation="loadingTitle ? 10 : 0"
      rounded
      min-width="200"
    >
      <v-card-title primary-title>
        <div>
          <h3 class="subtitle-1 ma-3 mgl-no-wordbreak">{{ loadingTitle }}</h3>
        </div>
      </v-card-title>
      <v-progress-circular color="secondary" indeterminate size="64"></v-progress-circular>
      <v-card-text class="ma-3" v-if="loadingTitle">
        <div class="text-center">{{ loadingText }}</div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['isLoading', 'refCount', 'loadingTitle', 'loadingText']),
  },
};
</script>

<style lang="scss" scoped></style>
