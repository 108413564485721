const msPerDay = 24 * 60 * 60 * 1000;

/**
 * Get date without time component
 * @param {Date} date date
 * @param {boolean} [utcFormat] if true, returned date will be in UTC format
 * @returns {Date}
 */
export function dateWithoutTime(date, utcFormat) {
  const dateCopy = new Date(date);
  if (utcFormat) {
    dateCopy.setUTCHours(0, 0, 0, 0);
    return dateCopy;
  }
  dateCopy.setHours(0, 0, 0, 0);
  return dateCopy;
}

/**
 * Add a number of days (can be negative) to a date
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export function addDaysToDate(date, days) {
  return new Date(date.getTime() + msPerDay * days);
}

/**
 * Get days between dates (rounded down)
 * @param {Date} from
 * @param {Date} to
 * @returns {number}
 */
export function daysBetweenDates(from, to) {
  const msBetweenDates = to.getTime() - from.getTime();
  return Math.floor(msBetweenDates / msPerDay);
}

/**
 * Get day index for timestamp
 * @param {string} playstartDateStr
 * @param {Date} [forDate] date to get day index for, if omitted current date is used
 * @returns {number}
 */
export function getDayIndex(playstartDateStr, forDate) {
  const targetDate = forDate || new Date();
  const date = dateWithoutTime(new Date(playstartDateStr), false);
  return daysBetweenDates(date, targetDate);
}

/**
 * Returns epoch in seconds for date
 * @param {Date} date
 * @returns {number}
 */
export function getEpochSeconds(date) {
  return Math.round(date.getTime() / 1000);
}

/**
 * Get date as ISO date string (YYYY-MM-DD)
 * @param {Date} date
 * @returns {string}
 */
export function getISODateString(date) {
  return date.toISOString().slice(0, 10);
}

/**
 * Returns the given date in a formatted fashion.
 * @returns {string} The date and time in the format "yyyy-MM-dd HH:mm:ss.SSS".
 */
export function getFormattedISODateTimeString(date) {
  let isoStr = date.toISOString();
  isoStr = isoStr.substring(0, isoStr.length - 1);
  const parts = isoStr.split('T');
  return `${parts[0]} ${parts[1]}`;
}
