import Vue from 'vue';
import VueI18n from 'vue-i18n';

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  sv: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
  },
};

Vue.use(VueI18n);

/**
 * Loads locale objects
 * @returns {VueI18n.LocaleMessages}
 */
function loadLocaleMessages() {
  // @ts-ignore
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  // @ts-ignore
  return messages;
}

/**
 * Get date time formats to use
 * @returns {VueI18n.DateTimeFormats}
 */
function getDateTimeFormats() {
  // @ts-ignore
  return dateTimeFormats;
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats: getDateTimeFormats(),
});
