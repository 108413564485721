import Vue from 'vue';
import { isLicenseValid, getLicenseExpiryTimestamp } from '../utils/licenses';

function formatLicenseWithFilter(license, filter) {
  if (license.updatedAt === undefined) return '';
  // Note: this had to be fixed for date comparison differences on Safari/Chrome
  // Make sure to validate date comparisons properly
  // https://stackoverflow.com/a/19691491/1132076
  switch (filter) {
    case 'isValid': {
      return isLicenseValid(license);
    }
    case 'validToDate': {
      const expireDate = getLicenseExpiryTimestamp(license);
      const validToDate = new Date(expireDate);
      validToDate.setDate(validToDate.getDate() - 1);
      return Vue.filter('dateFormat')(validToDate, 'YYYY-MM-DD');
    }
    case 'expireDate': {
      const expireDate = getLicenseExpiryTimestamp(license);
      return Vue.filter('dateFormat')(expireDate, 'YYYY-MM-DD');
    }
    default:
      return license.toString();
  }
}

Vue.filter('license', formatLicenseWithFilter);
