<template>
  <div>
    <v-dialog v-model="dialogOpen" max-width="520px" content-class="elevation-0">
      <template v-slot:activator="{ on }">
        <p
          class="p-0 m-0"
          style="
            text-decoration: underline;
            text-decoration-color: #c6c4c8;
            background-color: transparent;
            text-decoration-thickness: 1px;
            cursor: pointer;
          "
          v-on="on"
        >
          {{ $t('PHQ9.readmore') }}
        </p>
      </template>
      <div style="padding: 40px 10px 20px 10px; background-color: transparent; overflow: hidden">
        <v-card
          style="
            border-radius: 20px;
            text-align: center;
            vertical-align: top;
            display: inline-block;
            width: 100%;
            background-color: transparent;
          "
          color="primary"
        >
          <button type="button" class="close" @click="dialogOpen = false">X</button>
          <v-card-title
            style="display: flex; justify-content: center; padding: 0 30px 0 30px; text-align: left"
          >
            <h3 style="padding-bottom: 20px; padding-top: 20px">{{ $t('PHQ9.about') }}</h3>
            <p class="indepthinfo">
              {{ $t('PHQ9.inDepthInfo1') }}
            </p>
            <p class="grayreference">
              {{ $t('PHQ9.reference1') }}
            </p>
            <p
              style="
                line-height: 150%;
                color: grey;
                font-size: 12px;
                font-weight: 400;
                max-width: 500px;
                word-break: break-word;
              "
            >
              {{ $t('PHQ9.reference2') }}
            </p>
          </v-card-title>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Phq9Popup',
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>

<style scoped>
btn:hover {
  cursor: pointer;
}
.close {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #a55dff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 4px solid #301d59;
  z-index: 6000;
  margin: -10px;
  position: absolute;
  right: 0;
}
.indepthinfo {
  font-size: 14px;
  font-weight: 400;
  max-width: 500px;
  word-break: break-word;
}
.grayreference {
  line-height: 150%;
  color: grey;
  font-size: 12px;
  font-weight: 400;
  max-width: 500px;
  word-break: break-word;
}

@media only screen and (max-width: 500px) {
  .grayreference {
    font-size: 12px;
    line-height: 150%;
  }
  .indepthinfo {
    font-size: 12px;
    line-height: 150%;
  }
}
</style>
