<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.sleep') }}
      </h2>

      <p
        class="pa-5 text-center subtitle"
        v-html="
          $t('reports.sleepResultHtml', {
            averageSleepTime: '9h 13m',
            sleepWindow: '21:00 - 01:00',
          })
        "
      />
      <!-- Graph -->
      <section class="graph">
        <div class="yaxis">
          <p>21:00</p>
          <p>22:00</p>
          <p>23:00</p>
          <p>00:00</p>
          <p>01:00</p>
          <p>02:00</p>
          <p>03:00</p>
          <p>04:00</p>
          <p>05:00</p>
          <p>06:00</p>
          <p>07:00</p>
          <p>08:00</p>
        </div>
        <div class="content">
          <div class="bedtime-goal" style="top: 6px">
            <p>bedtime goal (21:30)</p>
          </div>
          <div class="bars">
            <div style="height: 280px; transform: translateY(110px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">23:25</p>
                    <p class="bar-indicator bottom">06:30</p>
                  </div>
                </template>
                <span>7h 05m</span>
              </v-tooltip>
            </div>
            <div style="height: 360px; transform: translateY(40px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">21:45</p>
                    <p class="bar-indicator bottom">06:45</p>
                  </div>
                </template>
                <span>7h</span>
              </v-tooltip>
            </div>
            <div style="height: 225px; transform: translateY(150px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">00:30</p>
                    <p class="bar-indicator bottom">08:00</p>
                  </div>
                </template>
                <span>7h 30m</span>
              </v-tooltip>
            </div>
            <div style="height: 315px; transform: translateY(75px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">22:30</p>
                    <p class="bar-indicator bottom">06:30</p>
                  </div>
                </template>
                <span>9h</span>
              </v-tooltip>
            </div>
            <div style="height: 0px; transform: translateY(100px)"></div>
            <div style="height: 425px; transform: translateY(6px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">21:00</p>
                    <p class="bar-indicator bottom">07:30</p>
                  </div>
                </template>
                <span>10h 30m</span>
              </v-tooltip>
            </div>
            <div style="height: 285px; transform: translateY(125px)">
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">23:50</p>
                    <p class="bar-indicator bottom">07:00</p>
                  </div>
                </template>
                <span>7h 10m</span>
              </v-tooltip>
            </div>
          </div>
          <div class="xaxis">
            <p>
              {{ $t('generic.weekdays.short.1') }} <br />
              5/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.2') }} <br />
              6/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.3') }} <br />
              7/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.4') }} <br />
              8/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.5') }} <br />
              9/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.6') }} <br />
              10/9
            </p>
            <p>
              {{ $t('generic.weekdays.short.7') }} <br />
              11/9
            </p>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ReportSleep',
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
$datesPos: 65px;
.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  padding-bottom: $datesPos;
}

.yaxis {
  display: flex;
  flex-direction: column;
  > p {
    flex: 1;
  }
  p:last-child {
    margin-bottom: 0 !important;
  }
}
.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  .bars {
    position: relative;
    display: flex;
    flex: 1;
    > div {
      position: relative;
      width: 100%;
      max-width: 30px;
      margin: 0 auto;
      flex: 1;
      border-radius: 50px;
      height: 80px;
      transition: all 0.3s;
      background-color: #5db6bf;
      border-color: #5db6bf;
      > div {
        height: 100%;
      }
      &:hover {
        background: #85d8e0 !important;
      }
      &:hover .bar-indicator {
        display: block;
      }
      .bar-indicator {
        display: none;
        position: absolute;
        width: 85px;
        font-size: 14px;
        color: #a55dff;
        margin: 1px 0;
      }
      .bar-indicator.top {
        border-top: 3px solid #a55dff;
        top: -3px;
        right: -15px;
      }
      .bar-indicator.bottom {
        border-bottom: 3px solid #a55dff;
        bottom: -3px;
        right: -15px;
      }
      @media (max-width: 500px) {
        max-width: 20px;
      }
    }
  }
}
.xaxis {
  display: flex;
  text-align: center;
  position: absolute;
  bottom: -$datesPos;
  width: 100%;
  > p {
    flex: 1;
    margin-bottom: 0;
  }
}

.bedtime-goal {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(#35d8db, 0.2);
  p {
    padding-left: 4px;
    color: #35d8db;
    border-bottom: 2px solid #35d8db;
  }
}

.v-tooltip__content.menuable__content__active::before {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #a55dff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
