<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    persistent
  >
    <v-card class="pb-3" :color="options.color">
      <v-card-title class="justify-center">
        <span v-if="options.asHtml" v-html="title"></span>
        <span v-else>
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text v-show="!!message" class="text-center">
        <span v-if="options.asHtml" v-html="message"></span>
        <span v-else>
          {{ message }}
        </span>
      </v-card-text>
      <v-card-actions class="pt-0 text-center" style="display: block">
        <v-spacer></v-spacer>
        <v-btn color="secondary" rounded depressed @click.native="agree">{{
          $t(options.actionButtonI18nKey)
        }}</v-btn>
        <v-btn
          color="background"
          rounded
          depressed
          @click.native="cancel"
          v-if="!options.disableCancel"
          >{{ $t(options.cancelButtonI18nKey) }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200,
      actionButtonI18nKey: 'generic.yes',
      cancelButtonI18nKey: 'generic.no',
      disableCancel: false,
      asHtml: false,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
