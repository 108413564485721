const uuid = require('uuid');
const { getHashDigest, constants } = require('./crypto');
const { arrayBufferToHexStr } = require('./base64');

/**
 * Convert a hex string to UUID format (truncates if string is too long)
 * @param {string} hexStr hex string input
 * @returns {string}
 */
function hexStrToUuid(hexStr) {
  const partLengths = [8, 4, 4, 4, 12];
  let partsIndex = 0;
  let separatorIndex = partLengths[partsIndex] - 1;
  let uuidStr = '';
  for (let i = 0; i < Math.min(hexStr.length, 32); i += 1) {
    uuidStr += hexStr[i];
    if (i === separatorIndex && partsIndex < partLengths.length - 1) {
      uuidStr += '-';
      partsIndex += 1;
      separatorIndex += partLengths[partsIndex];
    }
  }
  return uuidStr;
}

/**
 * Get a uuid hash from a message.
 * Note that as long as the input message is more than 16 characters (128 bit)
 * there is always a chance for hash-collision, but this chance should be really small in most cases.
 * Collision chance is p <= n(n-1)/2 x 1/(2 ^128)
 * For 100 000 messages (n) this is aproximately 10 ^-28 which is incredibly small chance
 * @param {string} message data as string
 * @returns {Promise<string>}
 */
export async function getUuid(message) {
  const hashDigest = await getHashDigest(message, constants.hash.sha1);
  // If uuid package upgrade to v9 can be performed (can be risky)
  // these two line can be replaced by return uuid.stringify(hashDigest);
  const hexStr = arrayBufferToHexStr(hashDigest, true);
  return hexStrToUuid(hexStr);
}

/**
 * Get a new random uuid (V4)
 * @returns {string}
 */
export function getV4Uuid() {
  return uuid.v4();
}
