<template>
  <v-card-text v-if="show">
    <p class="font-italic">{{ $t('account.noLicenses') }}</p>
    {{ $t('account.noLicensesDescription') }}<br />
    <div v-if="betaLinks.length > 0">{{ $t('account.noLicensesAcquire') }}<br /></div>
    <div v-for="(beta, i) in betaLinks" :key="i">
      <a :href="beta.link" target="_blank">
        {{ $t(beta.textId) }}
      </a>
      <br />
    </div>
  </v-card-text>
</template>
<script>
import { getStringConfig } from '../../utils/config';

const betaArr = [
  {
    country: 'usa',
    link: 'https://mindforcegamelab.com/fig/join-fig-beta-us/',
    textId: 'account.joinBetaUSA',
  },
  {
    country: 'germany',
    link: 'https://mindforcegamelab.com/fig/join-fig-beta-germany/',
    textId: 'account.joinBetaGermany',
  },
  {
    country: 'france',
    link: 'https://mindforcegamelab.com/fig/join-fig-beta-france/',
    textId: 'account.joinBetaFrance',
  },
];
const defaultRegions = ['usa', 'germany', 'france'];
export default {
  name: 'BetaSignupLinks',
  props: {
    show: Boolean,
  },
  data() {
    return {
      activeBetaRegions: defaultRegions,
    };
  },
  computed: {
    betaLinks() {
      return betaArr.filter((beta) => this.activeBetaRegions.includes(beta.country));
    },
  },
  methods: {
    async updateBetaLinks() {
      // Fetch beta signup status
      const availableBetas = await getStringConfig(this.$store, 'BETA_SIGNUP_REGIONS');
      if (availableBetas !== null) {
        const availableArr = availableBetas.split(',');
        this.activeBetaRegions = availableArr;
      }
    },
  },
  async mounted() {
    if (this.show) {
      await this.updateBetaLinks();
    }
  },
};
</script>
