/**
 * Get cron expression from time and days
 * @param {string} timeStr time like HH:mm
 * @param {string[]} daysArr array with valid days
 * @returns {string} cron expression
 */
export function getCronExpr(timeStr, daysArr) {
  const cronTime = timeStr.split(':');
  return `${parseInt(cronTime[1], 10)} ${parseInt(cronTime[0], 10)} * * ${daysArr.join()}`;
}

/**
 * Checks if cron expressions overlap
 * @param {string} expr1 cron expression
 * @param {string} expr2 cron expression
 * @returns {boolean} true if cron expression has an overlap
 */
export function hasDayOverlap(expr1, expr2) {
  const cronParts1 = expr1.split(' ');
  const cronParts2 = expr2.split(' ');
  if (cronParts1.length !== 5) {
    console.log(`invalid cron expr: ${expr1}`);
    return false;
  }
  if (cronParts2.length !== 5) {
    console.log(`invalid cron expr: ${expr2}`);
    return false;
  }
  if (cronParts1[0] !== cronParts2[0] || cronParts1[1] !== cronParts2[1]) {
    return false;
  }
  const daysStr1 = cronParts1[4];
  const daysStr2 = cronParts2[4];
  if (daysStr1 === daysStr2) {
    return true;
  }
  if (daysStr1 === '*' || daysStr2 === '*') {
    return true;
  }

  const days1 = daysStr1.split(',');
  const days2 = daysStr2.split(',');

  for (const day of days2) {
    if (days1.includes(day)) {
      return true;
    }
  }
  return false;
}
