import i18n from '../plugins/i18n';

/**
 * Displays a message using snackbar
 * @param {import("vuex").Store} store
 * @param {string|import("vue-i18n").TranslateResult} message
 * @param {'success'|'info'|'error'|'warning'} [displayColor]
 */
export function displayMessage(store, message, displayColor) {
  setLoading(store, false);
  const color = displayColor || 'info';
  store.commit('snackbar/setSnack', {
    message,
    color,
  });
}

/**
 * Displays error using snackbar
 * @param {import("vuex").Store} store
 * @param {string|import('vue-i18n').TranslateResult} [message]
 */
export function displayError(store, message) {
  displayMessage(store, message || 'Unknown error', 'error');
}

/**
 * Sets the loading popup text
 * @param {import("vuex").Store} store
 * @param {string|import("vue-i18n").TranslateResult} text
 */
export function setLoadingText(store, text) {
  store.commit('loadingText', text);
}

/**
 * Activates or deactivates the loading popup
 * @param {import("vuex").Store} store
 * @param {boolean} on if true loading is activated
 * @param {string} [titleId] id for title
 * @param {string} [textId] id for text
 */
export function setLoading(store, on, titleId, textId) {
  store.commit('loading', on);
  if (titleId) {
    store.commit('loadingTitle', i18n.t(`loading.${titleId}`));
  }
  if (textId) {
    setLoadingText(store, i18n.t(`loading.${textId}`));
  }
}
