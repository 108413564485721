<template>
  <div>
    <v-dialog v-model="displayDialog" max-width="520px" content-class="elevation-0">
      <template v-slot:activator="{ on }">
        <p color="secondary" rounded block large depressed v-on="on" class="mb-3"></p>
      </template>
      <div style="padding: 40px 10px 20px 10px; background-color: transparent; overflow: hidden">
        <v-card
          style="
            border-radius: 20px;
            text-align: center;
            vertical-align: top;
            display: inline-block;
            width: 100%;
            background-color: transparent;
          "
          color="primary"
        >
          <v-card-title
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="../assets/closeapp_popup picture.png" style="width: 120px; height: 200px" />
            <h3 style="padding: 25px 0px 20px 0px; max-width: 400px; word-break: break-word">
              {{ $t('fig.medication.reopen') }}
            </h3>
            <p
              style="
                padding: 10px 0px 15px 0px;
                font-weight: 400;
                max-width: 400px;
                word-break: break-word;
              "
            >
              {{ $t('fig.medication.restartGame') }}
            </p>
            <v-btn
              @click="displayDialog = false"
              color="secondary"
              rounded
              depressed
              x-large
              class="px-15"
              ><b> {{ $t('fig.medication.gotIt') }}</b>
            </v-btn>
            <br />
          </v-card-title>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'MedicationPopup',
  data() {
    return { displayDialog: true };
  },
};
</script>

<style scoped>
btn:hover {
  cursor: pointer;
}
.close {
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #a55dff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 4px solid #301d59;
  z-index: 6000;
  margin: -10px;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 450px) {
  p {
    font-size: 18px;
    line-height: 150%;
  }
}
@media only screen and (max-width: 450px) {
  p {
    font-size: 16px;
    line-height: 150%;
  }
}
</style>
