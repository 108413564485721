<template>
  <v-row>
    <v-col class="col-md-4 offset-md-4 text-center">
      <v-btn
        color="secondary"
        v-on:click="reroutePHQ9"
        rounded
        depressed
        x-large
        class="px-15 uppercase"
        ><b>
          {{ $t('PHQ9.start') }}
        </b></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StartPHQ9',
  methods: {
    reroutePHQ9() {
      this.$router.push({ name: 'phq9-form' });
    },
  },
};
</script>
<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
