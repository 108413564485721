import axios from 'axios';
import { Auth } from 'aws-amplify';
import i18n from '../plugins/i18n';
import { displayError, setLoading } from './loading';

/**
 * Sets loading text
 * @param {import("vuex").Store} store
 * @param {string|null} loadingMessage
 */
function setLoadingText(store, loadingMessage) {
  if (loadingMessage === null) {
    return;
  }
  const message = loadingMessage || 'almostDone';
  store.commit('loadingText', i18n.t(`loading.${message}`));
}

/**
 * Handles axios error
 * @param {import("vuex").Store} store
 * @param {Error} error
 * @param {(Error)=>Error} [onError]
 * @returns {Error}
 */
function handleAxiosError(store, error, onError) {
  if (onError) {
    setLoading(store, false);
    return onError(error);
  }
  displayError(store, error.message);

  return error;
}

/**
 * Get headers for axios request
 * @param {string} jwtToken
 * @param {any} [extraHeaders]
 * @returns {import('axios').AxiosRequestHeaders}
 */
function getHeaders(jwtToken, extraHeaders) {
  let headers = {};
  if (jwtToken !== null) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  if (extraHeaders) {
    headers = { ...headers, ...extraHeaders };
  }
  // @ts-ignore
  return headers;
}

/**
 * Get jwt token from current session
 * @returns {Promise<string>}
 */
export async function getJwtToken() {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}

/**
 * Get promise for POST request
 * @param {import("vuex").Store} store
 * @param {string} postUrl
 * @param {string} jwtToken
 * @param {object} data
 * @param {(any)=>any} onSuccess
 * @param {string} [loadingMessage]
 * @param {(Error)=>Error} [onError]
 * @param {object} [extraHeaders]
 * @returns {Promise<?>}
 */
export function getAxiosPostPromise(
  store,
  postUrl,
  jwtToken,
  data,
  onSuccess,
  loadingMessage,
  onError,
  extraHeaders,
) {
  setLoadingText(store, loadingMessage);
  const headers = getHeaders(jwtToken, extraHeaders);
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.VUE_APP_API}/${postUrl}`, data, {
        headers,
      })
      .then((res) => resolve(onSuccess(res)))
      .catch((error) => {
        reject(handleAxiosError(store, error, onError));
      });
  });
}

/**
 * Get promise for PUT request
 * @param {import("vuex").Store} store
 * @param {string} putUrl
 * @param {string} jwtToken
 * @param {object} data
 * @param {(any)=>any} onSuccess
 * @param {string} [loadingMessage]
 * @param {(Error)=>Error} [onError]
 * @param {object} [extraHeaders]
 * @returns {Promise<?>}
 */
export function getAxiosPutPromise(
  store,
  putUrl,
  jwtToken,
  data,
  onSuccess,
  loadingMessage,
  onError,
  extraHeaders,
) {
  setLoadingText(store, loadingMessage);
  const headers = getHeaders(jwtToken, extraHeaders);
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.VUE_APP_API}/${putUrl}`, data, {
        headers,
      })
      .then((res) => resolve(onSuccess(res)))
      .catch((error) => {
        reject(handleAxiosError(store, error, onError));
      });
  });
}

/**
 * Get promise for DELETE request
 * @param {import("vuex").Store} store
 * @param {string} deleteUrl
 * @param {string} jwtToken
 * @param {(any)=>any} onSuccess
 * @param {string} loadingMessage
 * @param {object} [extraHeaders]
 * @returns {Promise<?>}
 */
export function getAxiosDeletePromise(
  store,
  deleteUrl,
  jwtToken,
  onSuccess,
  loadingMessage,
  extraHeaders,
) {
  setLoadingText(store, loadingMessage);
  const headers = getHeaders(jwtToken, extraHeaders);
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.VUE_APP_API}/${deleteUrl}`, {
        headers,
      })
      .then((res) => resolve(onSuccess(res)))
      .catch((error) => {
        reject(handleAxiosError(store, error));
      });
  });
}

/**
 * Get promise for GET request
 * @param {import("vuex").Store} store
 * @param {string} getUrl
 * @param {string} jwtToken
 * @param {(any)=>any} onSuccess
 * @param {string} [loadingMessage]
 * @param {object} [extraHeaders]
 * @returns {Promise<?>}
 */
export function getAxiosGetPromise(
  store,
  getUrl,
  jwtToken,
  onSuccess,
  loadingMessage,
  extraHeaders,
) {
  setLoadingText(store, loadingMessage);
  const headers = getHeaders(jwtToken, extraHeaders);
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.VUE_APP_API}/${getUrl}`, {
        headers,
      })
      .then((res) => resolve(onSuccess ? onSuccess(res) : res))
      .catch((error) => {
        reject(handleAxiosError(store, error));
      });
  });
}
