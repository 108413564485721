import { getLicenseGroups } from '../utils/licenses';
import { apiDispatch } from '../utils/dispatch';
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';
import { FIG_APPID } from '../utils/constants';
import { addDaysToDate, dateWithoutTime, getISODateString } from '../utils/datetime';

/**
 * Get journal day index for user
 * @param {import("vuex").Store} store
 * @returns {number}
 */
export function getJournalDayIndex(store) {
  return store.getters['api/getJournalDayIndex'];
}

function getActiveAdherenceSensors(activeDoses, adherenceSensors) {
  const doseSensorIds = activeDoses.map((x) => x.sensorId);
  const sensorIdSet = new Set(doseSensorIds);
  const validForDoses = adherenceSensors.filter((x) => sensorIdSet.has(x.anonymousTemplateId));
  const sensorMap = new Map();
  for (const sensor of validForDoses) {
    const { anonymousTemplateId } = sensor;
    if (sensorMap.has(anonymousTemplateId)) {
      const prevEntry = sensorMap.get(anonymousTemplateId);
      if (sensor.createdAt > prevEntry.createdAt) {
        sensorMap.set(anonymousTemplateId, sensor);
      }
    } else {
      sensorMap.set(anonymousTemplateId, sensor);
    }
  }
  return Array.from(sensorMap.values());
}

export async function getCurrentAdherence(store, router) {
  const { doses, me } = store.state.api;
  if (!doses.length) {
    return 1;
  }
  const activeDoses = doses.filter((x) => !x.deleted_at);
  const doseCount = activeDoses.length;
  if (!doseCount) {
    return 1;
  }
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  const payload = {
    appId: FIG_APPID,
    privateKey: rsaKeys.privateKey,
    sensorTypeId: '396c14fa-e2e3-4510-914e-b0d7fe56bb49',
  };
  if (me && me.playstart) {
    payload.minDate = getISODateString(addDaysToDate(dateWithoutTime(me.playstart, false), -1));
  }
  const sensors = await apiDispatch(store, router, 'getSensorsLimited', payload);
  const activeSensors = getActiveAdherenceSensors(activeDoses, sensors);
  const dateNow = new Date();
  const endTimeStampStr = addDaysToDate(dateWithoutTime(dateNow, false), 1).toISOString();
  const sensorDataBackend = await apiDispatch(store, router, 'getTodaysSensorData', {
    appId: FIG_APPID,
    privateKey: rsaKeys.privateKey,
  });
  const sensorDataToday = sensorDataBackend.filter((x) => x.timestamp < endTimeStampStr);
  const activeIdSet = new Set(activeSensors.map((x) => x.id));
  const sensorsToCheck = sensorDataToday.filter((x) => activeIdSet.has(x.sensorId));
  const sensorDataMap = new Map();
  for (const sensorData of sensorsToCheck) {
    const { sensorId } = sensorData;
    if (sensorDataMap.has(sensorId)) {
      const prevEntry = sensorDataMap.get(sensorId);
      if (sensorData.createdAt > prevEntry.createdAt) {
        sensorDataMap.set(sensorId, sensorData);
      }
    } else {
      sensorDataMap.set(sensorId, sensorData);
    }
  }
  const sensorDataValues = Array.from(sensorDataMap.values()).map((x) => x.categoryIndexValue);
  return sensorDataValues.reduce((acc, x) => acc + x, 0) / doseCount;
}

/**
 * Get full user info
 * @param {import("vuex").Store} store
 * @returns {import('../store/modules/api').FullUserInformation|null}
 */
export function getUser(store) {
  return store.getters['api/me'];
}

/**
 * Get license groups csv for user
 * @param {import("vuex").Store} store
 * @returns {string}
 */
export function getUserGroupIdCsv(store) {
  const user = getUser(store);
  if (!user) {
    return null;
  }
  const arr = getLicenseGroups(user.licenses);
  if (!arr) {
    return null;
  }
  return arr.join(',');
}

/**
 * Get session id for user
 * @param {import("vuex").Store} store
 * @returns {string}
 */
export function getUserSessionId(store) {
  return store.getters['api/getUserSessionId'];
}
