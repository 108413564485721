import store from '../store';
import { fetchRsaKeysFromLocalStorage } from './crypto';

// Check repeatedly
const checkInterval = setInterval(validateSession, 10000);

// Check immediately (Why?)
validateSession();

/**
 * Validates the current session
 * @returns {Promise<any>}
 */
async function validateSession() {
  const { me } = store.state.api;

  // If is logged in
  if (me != null && !store.state.isLoading) {
    const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
    if (rsaKeys == null) {
      // Session invalid, force relogin
      clearInterval(checkInterval);
      store.commit('invalid', true);
    }
  }
}
