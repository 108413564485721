<template>
  <v-app id="app" :style="{ background: $vuetify.theme.themes[theme].background }">
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <TopNavBar v-if="isLoggedIn" />
    <v-main>
      <router-view />
      <SnackBar />
    </v-main>

    <LoadingOverlay />

    <Confirm ref="confirm" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import TopNavBar from './components/TopNavBar.vue';
import SnackBar from './components/SnackBar.vue';
import Confirm from './components/Confirm.vue';
import LoadingOverlay from './components/LoadingOverlay.vue';
import signOut from './utils/auth';
import { getDownloadPageUrl } from './utils/licenses';

export default {
  name: 'App',

  components: {
    TopNavBar,
    Confirm,
    SnackBar,
    LoadingOverlay,
  },
  computed: {
    ...mapState(['isInvalid']),
    theme() {
      // @ts-ignore
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    isLoggedIn() {
      // I do not understand comment below (what is this?):
      // TODO: once authentication is in place, check this properly here instead
      // @ts-ignore
      let routePath = this.$route.path.trim();
      if (routePath.endsWith('/')) {
        routePath = routePath.substr(0, routePath.lastIndexOf('/'));
      }
      const isLoggedIn = ![
        '/login',
        '/resetpassword',
        '/newpassword',
        '/loginsmall',
        '/signup',
        '/verifyemail',
        '/emailverified',
      ].includes(routePath);
      return isLoggedIn;
    },
  },
  methods: {
    openGame() {
      const downloadUrl = getDownloadPageUrl(this.$store, this.$i18n);
      window.open(downloadUrl, '_blank');
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
  },
  watch: {
    async isInvalid(invalid) {
      if (invalid) {
        if (!this.isLoggedIn) {
          return;
        }
        await signOut(this.$store, this.$router, false);
        this.$refs.confirm
          .open(this.$i18n.t('session.header'), this.$i18n.t('session.timeout'), {
            disableCancel: true,
            actionButtonI18nKey: 'generic.okay',
          })
          .then(async (confirm) => {
            if (confirm) {
              this.$router.go('/login');
            }
          });
      }
    },
  },
};
</script>

<style lang="sass">
@import './src/sass/mgl.scss'
</style>
