<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>
    <v-container fluid fill-height>
      <v-row>
        <v-col class="text-center mb-3 mt-12">
          <p v-if="verifySuccess" v-html="$t('emailVerified.successTextHtml')"></p>
          <p v-if="verifySuccess === false" v-html="$t('emailVerified.failedTextHtml')"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="display: flex; flex-direction: row; justify-content: space-around">
          <v-btn
            style="width: 400px; margin: auto; min-width: 280px"
            v-if="verifySuccess"
            :disabled="requestInProgress"
            x-large
            depressed
            rounded
            color="secondary"
            class="text-center initial"
            @click="login"
            >{{ $t('emailVerified.loginButtonText') }}</v-btn
          >
          <v-btn
            style="width: 300px; margin: auto; min-width: 280px"
            v-if="verifySuccess === false"
            :disabled="requestInProgress"
            x-large
            depressed
            rounded
            color="secondary"
            class="text-center initial truncated"
            @click="createNewAccount"
            >{{ $t('emailVerified.signupButtonText') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Logo from '../components/Logo.vue';
import { displayMessage } from '../utils/loading';
import { apiDispatch } from '../utils/dispatch';

export default {
  components: {
    Logo,
  },
  mounted() {
    const verifySuccess = this.$route.query.type === 'success';
    const queryLang = this.$route.query.lang;
    if (queryLang && ['en', 'sv', 'de', 'fr'].includes(queryLang)) {
      this.$i18n.locale = queryLang;
    }
    this.verifySuccess = verifySuccess;
    const snackMessageId = verifySuccess
      ? 'emailVerified.successMessage'
      : 'emailVerified.failedMessage';
    displayMessage(
      this.$store,
      this.$i18n.t(snackMessageId),
      verifySuccess ? 'success' : 'warning',
    );
  },
  data() {
    return {
      requestInProgress: false,
      verifySuccess: false,
    };
  },
  methods: {
    async login() {
      console.log('Logging in');
      const isLoggedIn = this.$store.state.api.me !== null;
      if (!isLoggedIn) {
        this.$router.push({ name: 'login' });
        return;
      }
      const user = await apiDispatch(this.$store, this.$router, 'getMe');
      if (user != null && user.emailVerified) {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
    createNewAccount() {
      console.log('Creating new account!');
      this.$router.push({ name: 'signup' });
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 360px) {
  .v-btn {
    max-width: 60%;
  }
}
</style>
