import { Auth } from 'aws-amplify';

/**
 * Signs the user out
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {boolean} [forwardToLoginPath]
 */
async function signOut(store, router, forwardToLoginPath) {
  await Auth.signOut();
  // eslint-disable-next-line no-param-reassign
  store.state.isInvalid = false;
  store.commit('api/resetState');
  store.commit('anonymousApi/resetState');
  if (forwardToLoginPath) {
    router.push({ name: 'login' });
  }
}

export default signOut;
